// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  IconButton,
  Divider,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import {
  ThemeProvider,
  withStyles,
  createStyles,
  Theme,
  styled
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import EndUserSignInController from "./EndUserSignInController";
import {
  themeWhite,
  themeYellow,
  commonStyles,
  webStyles,
} from "../../../framework/src/enduserTheme";
import { everybrandLogo } from "./assets";
import MobileLoader from "../../../components/src/MobileLoader";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { handleAndConditions, handleOrConditions } from "../../../components/src/CommonFunctions";
import { ArrowBackIosRounded } from "@material-ui/icons";
const configJSON = require("./config.js")

const CustomCheckbox = styled(Checkbox)({
  color: '#64748B',
  '&.Mui-checked': {
    color: '#64748B',
  },
  '& .MuiIconButton-label': {
    borderRadius: '4px',
  },
  '& .MuiSvgIcon-root': {
    borderRadius: '4px',
  },
  padding: '1px',
});

const CustomFormControlLabel = styled(FormControlLabel)({
  color: '#0F172A',
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: "20px",
    marginLeft: '4px',
    fontFamily: "Inter, 'sans-serif'",
  },
  alignItems: 'flex-start',
  margin: "0"
});

export class EndUserSignIn extends EndUserSignInController {
  render() {
    const { classes }: any = this.props;
    const { loading, themeSwitch, phoneNumberError, phoneNumberErrorMsg } = this.state

    const validation_Schema_signin = yup.object({
      userNumber: yup
        .string()
        .required("Phone number is required ")
        .min(10, "Must be at least 10 digits "),
      termsAndConditions: yup.boolean()
      .oneOf([true], configJSON.termsAndConditionsErrorMessage)
    });

    return (
      <ThemeProvider theme={themeSwitch ? themeYellow : themeWhite}>
        <MobileLoader loading={loading} />
        <CssBaseline />
        <Container
          data-test-id="mainContainerSignIn"
          maxWidth="sm"
          className={classes.mainContainerSignIn}
        >
          <Formik
            initialValues={{
              userNumber: "",
              termsAndConditions: false
            }}
            validationSchema={validation_Schema_signin}
            onSubmit={(values) => {
              this.senOtpToUserSignINFlow(values.userNumber);
            }}
            data-test-id="mainFormSignIN"
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <>
                <Box className={classes.backIconBoxSignIn}>
                  <IconButton onClick={this.handleBULoginRedirect}>
                    <ArrowBackIosRounded fontSize="small" />
                  </IconButton>
                </Box>
                <Box className={classes.brandLogoWrapper}>
                  <img src={everybrandLogo} alt="Everybrand" />
                </Box>
                <Box className={classes.flexDisplaySignInCol}>
                  <Typography data-test-id="mobile-number-label" className={classes.mobileLabel}>
                    {configJSON.mobileNumberLabel}
                  </Typography>
                  <Box className={classes.marginAutoSignIN}>
                    <PhoneInput
                      data-test-id="userNumber"
                      country={configJSON.DEFAULT_COUNTRY_NAME}
                      value={values.userNumber}
                      onChange={handleChange("userNumber")}
                      onBlur={handleBlur("userNumber")}
                      placeholder={configJSON.enterMobileNumber}
                      specialLabel={""}
                      buttonStyle={handleOrConditions(
                        themeSwitch,
                        webStyles.buttonStyleYellow,
                        webStyles.buttonStyleLight
                      )}
                      inputStyle={handleOrConditions(
                        themeSwitch,
                        webStyles.phoneInputYellow,
                        webStyles.phoneInputLight
                      )}
                    />
                  </Box>
                  {handleAndConditions(touched.userNumber , errors.userNumber , (
                    <Typography className={classes.errorMsgSignIN}>
                      {errors.userNumber}
                    </Typography>
                  ))}
                  {handleOrConditions(phoneNumberError , (
                    <Typography className={classes.errorMsg}>
                      {phoneNumberErrorMsg}
                    </Typography>
                  ) , null)}
                </Box>
                <Box>
                  <Button
                    onClick={() => handleSubmit()}
                    className={classes.buttonPrimarySignUp}
                    data-test-id="submitButtonSignIN"
                  >
                    {configJSON.labelTitle}
                  </Button>

                  <Box className={classes.dividerWrapper}>
                    <Divider />
                    <Typography>{configJSON.orLabel}</Typography>
                    <Divider />
                  </Box>

                  <Button
                    className={classes.businessLoginBtn}
                    data-test-id="bu-sign-in"
                    onClick={this.handleBULoginRedirect}
                  >
                    {configJSON.businessLoginLabel}
                  </Button>

                  <Box marginY={4}>
                    <CustomFormControlLabel
                      control={
                        <CustomCheckbox
                          checked={values.termsAndConditions}
                          onChange={handleChange}
                          name="termsAndConditions"
                        />
                      }
                      label={configJSON.termsAndConditionsText}
                    />
                    <ErrorMessage
                      component="p"
                      name="termsAndConditions"
                      className={classes.errorMsgSignIN}
                    />
                  </Box>

                  <Typography className={classes.signUpContainer}>
                    {configJSON.doNotHaveAccountMessage}
                    <span data-test-id="eu-redirect-signup-btn" onClick={this.handleSignUpClick}>
                      {configJSON.signUpLabel}
                    </span>
                  </Typography>
                </Box>
              </>
            )}
          </Formik>
        </Container>
      </ThemeProvider>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    errorMsgSignIN: {
      color: "red",
      fontSize: "12px",
      padding: "5px 2px",
      fontFamily: "Inter, 'sans-serif'",
      margin: "0px"
    },
    marginAutoSignIN: {
      marginTop: "10px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      width: "100%",
      "& .react-tel-input .form-control": {
        border: "none",
        borderRadius: "8px !important",
        padding: "18.5px 14px 18.5px 40px",
        "&:focus": {
          boxShadow: "none",
          border: "none",
        },
        "&:hover": {
          border: "none"
        }
      },
      "&:hover": {
        border: "1px solid #1976d2",
        "&:hover": {
            borderRadius: "8px",
        }
      },
      "&:focus-within": {
        border: "2px solid #1976d2",
      },
      "& .flag-dropdown": {
        border: "none",
        borderRadius: "8px !important",
        "& .selected-flag": {
          width: "40px",
          "&:hover": {
            borderRadius: "8px",
          },
        },
        "& .arrow": {
          display: "none"
        }
      },
    },
    flexDisplaySignInCol: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "30px"
    },
    dividerWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "25px",
      justifyContent: "space-between",
      margin: "30px 0px",
      "& hr": {
        flex: 1,
        backgroundColor: "#000"
      },
      "& p": {
        color: "#000",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Inter, 'sans-serif'"
      }
    },
    businessLoginBtn: {
      ...commonStyles.mobileButton,
      width: "100%",
      borderRadius: "8px",
      marginBottom: '0px'
    },
    signUpContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      marginTop: "30px",
      color: "#0F172A",
      lineHeight: "24px",
      fontFamily: "Inter",
      fontWeight: 400,
      "& > span": {
        fontWeight: '700',
        lineHeight: "24px",
        fontFamily: "Inter",
        cursor: "pointer"
      }
    },
    mobileLabel: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: "Inter, 'sans-serif'",
      color: "#334155"
    },
    mainContainerSignIn: {
      ...commonStyles.mobileContainer,
      padding: "25px 29px",
      justifyContent: "center",
      maxWidth: "500px",
      margin: "25px auto",
      height: "100%"
    },
    buttonPrimarySignUp: {
      padding: "14px 12px",
      height: "56px",
      textTransform: "none",
      width: "100%",
      borderRadius: '8px',
      backgroundColor: "#FFC629",
      "& span": {
        fontFamily: "Inter, 'sans-serif'",
        fontSize: "16px",
        color: "#0F172A",
        fontWeight: 700
      },
      "&:hover": {
        backgroundColor: "#FFC629",
      }
    },
    backIconBoxSignIn: {
      margin: "10px 0px",
      display: "flex",
      justifyContent: "flex-start",
      "& > button": {
        padding: "5px",
        "& svg": {
          color: "#334155"
        }
      },
      "@media (max-width: 768px)": {
       textAlign: 'center',
       },
    },
    brandLogoWrapper: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px"
    }
  });

export default withStyles(styles)(EndUserSignIn);
// Customizable Area End
