// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

interface Props {
    navigation: any
};

interface S {
  currentTab: number | null;
}

interface SS {}

export default class MobileNavEndUserController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      currentTab: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.updateActiveTab(window.location.pathname);
  }

  updateActiveTab(path: string) {
    const convertedPath = path.toLocaleLowerCase()
    let activeTab = null;
    if(convertedPath.includes("dashboard")) {
      activeTab = 0;
    } else if (convertedPath.includes("messages")) {
      activeTab = 1;
    } else if (convertedPath.includes("contacts")) {
      activeTab = 2;
    } else if (convertedPath.includes("automations")) {
      activeTab = 3;
    } else if (convertedPath.includes("notifications")) {
      activeTab = 4;
    }
    this.setState({ currentTab: activeTab });
  }

  handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ currentTab: newValue });

    switch (newValue) {
      case 0:
        this.handleRedirect("EuDashboard")
        break;
      case 1:
        this.handleRedirect("EuTredning")
        break;
      case 2:
        this.handleRedirect("EupostCreate")
        break;
      case 3:
        this.handleRedirect("EuNotification")
        break;
        case 4:
            this.handleRedirect("EuProfileView")
            break;
      default:
        break;
    }
  };

  handleRedirect = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };
}
// Customizable Area End
