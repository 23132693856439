// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  APIRequest,
  DashboardData,
  PostType,
  DashboardAccount,
  FollowerBack
} from "./interfaces";
import { Message } from "../../../framework/src/Message";
import { getToken, setToken } from "../../../components/src/AuthService";
import {
  getStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import { createRef } from "react";

const {baseURL} = require("../../../framework/src/config");
interface BrandAttributesTrending {
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  activated: boolean;
  country_code: string;
  email: string;
  full_name: string | null;
  pin: number;
  sign_up_step: number;
  business_website: string;
  zip_code: string;
  social_phone_number: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  is_first_login: boolean;
  profile_pic: {
    url: string;
  } | null;
  follows: {
    followers: string;
    followings: string;
  };
  subscription_paused: boolean;
  cover_photo: {
    url: string;
  } | null;
}
interface BrandTrending {
  id: string;
  type: string;
  attributes: BrandAttributesTrending;
}

export const configJSON = require("./config.js");

enum CommentsOrderEnum {
  ASC = "asc",
  DESC = "desc"
}

interface IPostListResponse {
  posts: { data: PostType[] }
  pagination: {
    current_page: number,
    next_page: number,
    prev_page: number | null,
    total_pages: number,
    total_count: number,
  }
}

export interface Props {
  navigation?: any;
  id?: string;
  classes?: any;
  hidePostApicall?: (values: any) => void;
  banUserApiCall?: (values: any) => void;
  postCommentApiCall?: (values: any) => void;
  showAlert?: boolean;
  showSuccess?: string;
  errorAlertMessage?: boolean;
  dashboardApicall?: Function;
  deleteCommentApiCall?: (id: number) => void;
  archiveCommentApiCall?: (values: any) => void;
  revertArchiveCommentApiCall?: (id: number) => void;
  achiveId?: number;
  disableClass?: boolean;
}
interface S {
  isLoading: boolean;
  account?: DashboardAccount;
  posts: PostType[];
  top6: any;
  showSucess: string;
  isShowAlert: boolean;
  errorAlertMessage: boolean;
  achiveId: any;
  isLoader: boolean;
  isPaused: boolean;
  scrlltoPost: number;
  notificationData: any;
  followersBack: FollowerBack[];
  trendingpost: any[];
  brandsIFollowTrending: BrandTrending[];
  suggestedBrandsTrending: BrandTrending[];
  top6Trending: BrandTrending[]
  postLoader: boolean
  postPageNo: number
  postTotalPages: number
  hidePostId: string
  selectedPostId: string
  replyPostId: string;
  postTrendingPage: number;
  isSortingComments: boolean
  videoRefs: React.RefObject<HTMLVideoElement>[];
  reactionCount: {
    likes: number,
    comments: number,
    reviews: number
  }
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  dashboardPageApiCallId: string = "";
  tokenUpdateApiCallId: string = "";
  hidePostCallId: string = "";
  banUserCallId: string = "";
  postCommentCallId: string = "";
  deleteCommenCallID: string = "";
  archiveCommentCallId: string = "";
  revertArchiveCommentCallID: string = "";
  getCommentsApiCallId: string = "";
  likePostApiCallID: string = "";
  undoLikeApiCallId: string = ""
  followersBackUserApiCallId: string = "";
  euTrendingPostApiCallId: string = "";
  createFollowerApiCallId: string = "";
  euTrendingBrandsApiCallId: string = "";
  followSuggestedBrandApiCallId: string = ""
  getPostsApiCallId: string = ""
  postPollVoteCallId: string = ""
  postPollDeleteVoteCallId: string = ""
  observer: IntersectionObserver | null = null;
  getReactionCountApiCallId: string = "";
  socketVar = createRef<WebSocket>().current

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.ConnectTOSidebar),
      getName(MessageEnum.DashboardRedirection)
    ];
    this.receive = this.receive.bind(this);
    this.state = {
      isLoader: true,
      isLoading: false,
      account: undefined,
      posts: [],
      top6: [],
      showSucess: "",
      isShowAlert: false,
      errorAlertMessage: false,
      achiveId: 0,
      isPaused: false,
      scrlltoPost: 0,
      notificationData: {},
      followersBack: [],
      trendingpost: [],
      brandsIFollowTrending: [],
      suggestedBrandsTrending: [],
      top6Trending: [],
      postLoader: false,
      postPageNo: 1,
      postTotalPages: 0,
      hidePostId: "",
      selectedPostId: "",
      replyPostId: "",
      postTrendingPage: 1,
      isSortingComments: false,
      videoRefs: [],
      reactionCount: {
        likes: 0,
        comments: 0,
        reviews: 0
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getDashboardData();
    this.getPostsApi()
    this.getFollowersBackData();
    this.handleTrendingPostApiCall();
    this.getSuggestedAndFollowBrandTrending();
    this.getReactionCount()
  }

  async componentWillUnmount() {
    this.observer?.disconnect();
  }

  getReactionCount = () => {
    this.getReactionCountApiCallId = this.makeRequest({
      endpoint: configJSON.reactionCountGetUrl,
      method: configJSON.dashboarApiMethodType,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      }
    });
  }

  getSuggestedAndFollowBrandTrending = () => {
    const EuToken = window.localStorage.getItem("EUAuthToken");
    this.euTrendingBrandsApiCallId = this.makeRequest({
      endpoint: configJSON.endPointApiGetEUTrendingBrands,
      method: configJSON.methodTypeApiGetEUTrendingBrands,
      headers: {
        "Content-Type": configJSON.contentTypeApiGetEUTrendingBrands,
        token: EuToken || ""
      }
    });
  };

  initializeSocketForLiveStreaming = async () => {
    const token: string = await getStorageData("authToken");
    const baseURLEdit: string = baseURL.replace("https", "wss");
    this.socketVar = new WebSocket(`${baseURLEdit}/cable?token=${token}`);
    this.socketVar.onopen = this.getSocketOpen
    this.socketVar.onmessage =this.getSocketMessage
  }

  getSocketOpen = () => {
    setTimeout(this.handleTimer, 3000);
  };

  handleTimer = () => {
    const initialMessageObjForReaction = {"command":"subscribe","identifier":JSON.stringify({channel:"ReactionsChannel",
      "account_id": this.state.account?.id
     })}
    this.socketVar?.send(JSON.stringify(initialMessageObjForReaction));
  }
  
  getSocketMessage=(event:any)=>{
    const data = JSON?.parse(event?.data)
    if (typeof data?.message === 'object') {
      const socketData = data.message
      if(socketData?.hasOwnProperty("likes_count")){
      this.setState({
        reactionCount: {
          likes: socketData.likes_count,
          comments: socketData.comments_count,
          reviews: socketData.reviews_count,
        }
      })}
    }
  }

  handleVote = (optionId: number, postId: string) => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.postPollVoteCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostsPollApiEndpoint + `/${postId}/vote?option_id=${optionId}`
    );
    
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  handleDeleteVote = (optionId: number, postId: string) => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.postPollDeleteVoteCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostsPollApiEndpoint + `/${postId}/delete_vote?option_id=${optionId}`
    );

    
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDelMethod
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ): void {
    if (
      this.state.account !== prevState.account &&
      window.localStorage.getItem("rememberMe")
    ) {
      this.updateToken();
    }

    if (
      this.state.account !== prevState.account && this.state.account?.id
    ) {
      this.initializeSocketForLiveStreaming()
    }

    if(prevState.postPageNo !== this.state.postPageNo) {
      this.getPostsApi()
    }

    if (prevState.trendingpost !== this.state.trendingpost) {
      this.initIntersectionObv()
      this.setState({videoRefs : this.state.trendingpost.map(() => createRef<HTMLVideoElement>())});
    }

    if (prevState.posts !== this.state.posts) {
      this.initIntersectionObv()
      this.setState({videoRefs : this.state.posts.map(() => createRef<HTMLVideoElement>())});
    }
  }

  initIntersectionObv () {
    if (this.observer) {
      this.observer.disconnect();
    }
    
    setTimeout(() => {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const video = entry.target as HTMLVideoElement;
            if (entry.isIntersecting) {
              video.play();
            } else {
              video.pause();
            }
          });
        },
        { threshold: 0.5 }
      );
      
      this.state.videoRefs.forEach((ref) => {
        if (ref.current) {
          this.observer?.observe(ref.current);
        }
      });
    }, 0); 
  }

  async receive(_from: string, message: Message) {
    if (message.id === getName(MessageEnum.ConnectTOSidebar)) {
      let data = message.getData(getName(MessageEnum.ShouldUpdate));
      if (data && data.refresh) {
        this.getDashboardData();
      }
    }
    if (getName(MessageEnum.DashboardRedirection) === message.id) {
      const params = message.getData(getName(MessageEnum.DashboardRedirection));
      this.setState({
        scrlltoPost: params.attributes.post_id,
        notificationData: params
      });
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleResponseblock(responseJson, apiRequestCallId);

    if (apiRequestCallId === this.getReactionCountApiCallId) {
      this.setState({reactionCount: {
        likes: responseJson.likes_count,
        comments: responseJson.comments_count,
        reviews: responseJson.reviews_count
      }})
    }

    if (apiRequestCallId === this.followersBackUserApiCallId) {
      this.handleGetFollowersBackApiResponse(responseJson?.followers?.data);
    }

    if (apiRequestCallId === this.createFollowerApiCallId) {
      if (responseJson.meta) {
        this.getFollowersBackData();
      }
    }

    if(responseJson && !responseJson.errors && !responseJson.error) {
      if(apiRequestCallId === this.followSuggestedBrandApiCallId) {
        this.getSuggestedAndFollowBrandTrending()
      }

      this.handleSuccessApiResponse(apiRequestCallId, responseJson)
    }
  }

  handleSuccessApiResponse = (apiRequestCallId: string, responseJson: IPostListResponse) => {
    if(apiRequestCallId === this.getPostsApiCallId) {
      const { posts, postPageNo } = this.state
      const { posts: { data },  pagination } = responseJson
      const {  total_pages } = pagination
      let allPosts = []

      if(postPageNo === 1) {
        allPosts = data
      } else {
        allPosts = [...posts, ...data]
      }
      this.setState({
        posts: allPosts,
        postLoader: false,
        postTotalPages: total_pages
      })
    }
  }

  handleGetFollowersBackApiResponse = (followers: any) => {
    this.setState({ followersBack: followers });
  };

  handleSortingCommentsChange = (value: boolean) => {
    this.setState({ isSortingComments: value })
  }

  followSuggestedBrandApi = (brandId: string) => {
    const token = window.localStorage.getItem(configJSON.EU_AUTH_TOKEN) || ""
    const body = {
      data:{
          attributes:{
              followed_user_id: brandId
          }
      }
    }
    this.followSuggestedBrandApiCallId = this.makeRequest({
      method: configJSON.httpPostMethod,
      endpoint: configJSON.followBrandApiEndpoint,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token
      },
      body: JSON.stringify(body)
    })
  }

  getFollowersBackData = () => {
    this.setLoaderState(true);
    this.followersBackUserApiCallId = this.makeRequest({
      endpoint: configJSON.followersBackGetUrl,
      method: configJSON.dashboarApiMethodType,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      }
    });
  };

  postCreateFollower = (followerId: any) => {
    const payload = {
      data: {
        attributes: {
          followed_user_id: followerId
        }
      }
    };
    this.setLoaderState(true);
    this.createFollowerApiCallId = this.makeRequest({
      endpoint: configJSON.createFollowerEndPoint,
      method: configJSON.httpPostMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      },
      body: JSON.stringify(payload)
    });
  };

  getDashboardData = () => {
    this.setLoaderState(true);
    this.dashboardPageApiCallId = this.makeRequest({
      endpoint: configJSON.dashboardGetUrl,
      method: configJSON.dashboarApiMethodType,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      }
    });
  };

  getPostsApi = () => {
    const { postPageNo } = this.state
    this.setState({ postLoader: true })
    const token = getToken()
    const endpoint = `${configJSON.getPostsApiEndpoint}?page=${postPageNo}&per_page=${configJSON.POST_PER_PAGE}`

    this.getPostsApiCallId = this.makeRequest({
      endpoint,
      method: configJSON.httpGetMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token
      }
    });
  };

  handlePostPageNoChange = (pageNo: number) => {
    this.setState({ postPageNo: pageNo })
  }

  handleLikePostAPICall = (data: any, likeType: string, postId?: string) => {
    let payload;
    if (likeType === "Post") {
      const postIndex = this.state.posts.findIndex(post => post.id == data.id);
      if (postIndex !== -1) {
        const updatedPosts = [...this.state.posts];
        updatedPosts[postIndex].attributes.is_liked = !updatedPosts[postIndex].attributes.is_liked;
        updatedPosts[postIndex].attributes.likes_count += 1;
        this.setState({ posts: updatedPosts });
      }

      payload = {
        like: {
          likeable_id: data.id,
          likeable_type: "BxBlockPosts::Post"
        }
      };
    } else if (likeType === "Comment") {
      const postIndex = this.state.posts.findIndex(post =>
        post.attributes && post.attributes.comments && post.attributes.comments.data.find(comment => comment.id == data.id)
      );

      if (postIndex !== -1) {
        const updatedPosts = [...this.state.posts];
        const comments = updatedPosts[postIndex].attributes.comments;

        if (comments && comments.data) {
          const commentIndex = comments.data.findIndex(comment => comment.id == data.id);

          if (commentIndex !== -1) {
            const isLiked = comments.data[commentIndex].attributes.already_liked;
            comments.data[commentIndex].attributes.already_liked = !isLiked;
            comments.data[commentIndex].attributes.likes_count += 1;
            this.setState({ posts: updatedPosts });
          }
        }
      }

      payload = {
        like: {
          likeable_id: data.id,
          likeable_type: "BxBlockComments::Comment"
        }
      };
      postId && this.setState({ selectedPostId: postId })
    }

    this.likePostApiCallID = this.makeRequest({
      endpoint: configJSON.likeAPIEndPoint,
      method: configJSON.httpPostMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      },
      body: JSON.stringify(payload)
    });
  };

  handleUndoLikeAPICall = (likeableId: string, likeType: string, postId?: string) => {
    postId && this.setState({ selectedPostId: postId })
    const endPoint = `${configJSON.undoLikeApiEndpoint}?likeable_id=${likeableId}&likeable_type=${configJSON.likeableTypes[likeType]}`

    this.undoLikeApiCallId = this.makeRequest({
      endpoint: endPoint,
      method: configJSON.httpDelMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      }
    });
  }

  updateToken = () => {
    if (this.state.account) {
      this.tokenUpdateApiCallId = this.makeRequest({
        endpoint: `${configJSON.accountApiEndpoint}/${this.state.account.id
          }/get_new_token`,
        method: configJSON.dashboarApiMethodType,
        headers: {
          "Content-Type": configJSON.dashboarContentType,
          token: getToken()
        }
      });
    }
  };

  hidePostApiCall = (values: any) => {
    this.hidePostCallId = this.makeRequest({
      endpoint: configJSON.hidePostEndPoint,
      method: configJSON.httpPostMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      },
      body: JSON.stringify(values)
    });

    this.setState(prevState => {
      const updatedPosts = prevState.posts.filter(post => post.id !== values.hides.hideable_id);
      return { posts: updatedPosts, hidePostId: values.hides.hideable_id };
    });
  };

  makeRequest = ({ endpoint, method, headers, body }: APIRequest) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  banUserApiCall = (values: any) => {
    this.banUserCallId = this.makeRequest({
      endpoint: configJSON.hidePostEndPoint,
      method: configJSON.httpPostMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      },
      body: JSON.stringify(values)
    });
  };

  getPostCommentsApiCall = (postId: string, sortOrder?: CommentsOrderEnum) => {
    const sort = sortOrder || CommentsOrderEnum.DESC
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCommentsApiCallId = requestMessage.messageId;
    const endPoint = `${configJSON.postCommentEndPoint}?commentable_id=${postId}&commentable_type=${configJSON.PostClass}&sort=${sort}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  postCommentApiCall = (formData: any, postId?: string) => {
    postId && this.setState({ replyPostId: postId })
    this.postCommentCallId = this.makeRequest({
      endpoint: configJSON.postCommentEndPoint,
      method: configJSON.httpPostMethod,
      headers: {
        token: getToken()
      },
      body: formData
    });
  };

  deleteCommentApiCall = (id: number, postId: string) => {
  
    this.deleteCommenCallID = this.makeRequest({
      endpoint: `${configJSON.postCommentEndPoint}/${id}`,
      method: configJSON.httpDelMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      }
    });
  
    this.setState(prevState => {
      const updatedPosts = prevState.posts.map(post => {
        if (post.id === postId) {
          const updatedComments = post.attributes.comments.data.filter(comment => comment.id !== id.toString());
          const updatedCommentsCount = post.attributes.comments_count - 1;
          return {
            ...post,
            selectedPostId: postId,
            attributes: {
              ...post.attributes,
              comments: {
                ...post.attributes.comments,
                data: updatedComments
              },
              comments_count: updatedCommentsCount
            }
          };
        }
        return post;
      });
  
      return { posts: updatedPosts };
    });
  };
  

  archiveCommentApiCall = (values: any) => {
    this.archiveCommentCallId = this.makeRequest({
      endpoint: configJSON.archiveCommentEndPoint,
      method: configJSON.httpPostMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      },
      body: JSON.stringify(values)
    });
  };

  handleTokenUpdateApiResponse = (response: { token: string }) => {
    setToken(response.token);
  };

  revertArchiveCommentApiCall = (values: any) => {
    this.revertArchiveCommentCallID = this.makeRequest({
      endpoint: configJSON.archiveCommentEndPoint,
      method: configJSON.httpDelMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      },
      body: JSON.stringify(values)
    });
  };  

  setLoaderState = (isLoading: boolean) => {
    this.setState({ isLoading });
  };

  handleDashboardAPIResponse = (response: DashboardData) => { 
    this.setLoaderState(false);
    this.setHomePageData(response);
   };

  setHomePageData = (response: DashboardData) => {
    const account = response.account.data;
    const top6 = response.top6.data || []

    this.setState({ account, top6 });
    const subscriptionPaused = this.state.account
      ? this.state.account.attributes.subscription_paused
      : false;

    if (subscriptionPaused === true) {
      setStorageData("subscriptionStatus", subscriptionPaused);
      this.setState({ isPaused: true });
    } else {
      setStorageData("subscriptionStatus", "false");
      this.setState({ isPaused: false });
    }
    this.setState({ isLoader: false });
  };

  sendInfoToMainLayout = (data: any) => {
    const msg = new Message(getName(MessageEnum.ConnectTOSidebar));
    msg.addData(getName(MessageEnum.ShouldUpdate), data);
    this.send(msg);
  };

  isShowCell = () => {
    setTimeout(() => this.setState({ isShowAlert: false }), 3000);
  };

  handleCommentIntoView = (commentId: string) => {
    const commentElement = document.getElementById(`comment-${commentId}`)
    if(commentElement) {
      commentElement.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  }

  hidePostApiResponse = (response: any) => {
    if (response) {
      if (response.message === configJSON.successfullyHiddenPostMessage) {
        const { posts, hidePostId } = this.state
        const updatedPost = [...posts].filter((post) => post.id !== hidePostId)
        this.setState(
          {
            isShowAlert: true,
            showSucess: response.message,
            errorAlertMessage: false,
            posts: updatedPost
          },
          () => {
            this.isShowCell();
          }
        );
      } else {
        this.setState(
          {
            isShowAlert: true,
            showSucess: response.message,
            errorAlertMessage: true
          },
          () => {
            this.isShowCell();
          }
        );
      }
    }
  };

  postCommentApiResponse = (response: any) => {
    if (response.errors) {
      this.setState(
        {
          isShowAlert: true,
          showSucess: response.errors[0].comment,
          errorAlertMessage: true
        },
        () => {
          this.isShowCell();
        }
      );
    } else {
      this.handleCommentSuccessfulResponse(response);
    }
  };

  banUserApiResponse = (response: any) => {
    if (response) {
      if (response.message) {
        this.setState({ showSucess: response.message });
        this.handlePostPageNoChange(1)
      }
    }
  };

  handleCommentSuccessfulResponse = (response: any) => {
    const { replyPostId } = this.state
    const newComment = response.data;

    if(replyPostId) {
      this.getPostCommentsApiCall(replyPostId)
      return
    }

    this.setState(prevState => {
      const postIndex = prevState.posts.findIndex(
        post => post.id == newComment.attributes.commentable_id
      );

      if (postIndex !== -1) {
        const updatedPosts = [...prevState.posts];
        const updatedPost = { ...updatedPosts[postIndex] };

        if (updatedPost.attributes.comments_count === undefined) {
          updatedPost.attributes.comments_count = 0;
        }
        updatedPost.attributes.comments_count += 1;

        if (updatedPost.attributes.comments) {
          updatedPost.attributes.comments.data = [
            newComment,
            ...updatedPost.attributes.comments.data
          ];
        } else {
          updatedPost.attributes.comments = {
            data: [newComment]
          };
        }

        updatedPosts[postIndex] = updatedPost;

        return {
          ...prevState,
          posts: updatedPosts,
          isShowAlert: true,
          showSucess: response.meta.message,
          errorAlertMessage: false
        };
      } else {
        return {
          ...prevState,
          isShowAlert: true,
          showSucess: response.meta.message,
          errorAlertMessage: false
        };
      }
    }, () => {
      this.isShowCell();
      this.handleCommentIntoView(newComment.id)
    });
  };


  likePostApiResponse = (response: any) => {
    if (response) {
      const { selectedPostId } = this.state
      if(selectedPostId) {
        this.getPostCommentsApiCall(selectedPostId)
      }
      if (response.errors) {
        this.setState(
          {
            isShowAlert: true,
            showSucess:
              response.errors[0].comment || response.errors[0].commentable,
            errorAlertMessage: true
          },
          () => {
            this.isShowCell();
          }
        );
      }
    }
  };

  undoLikeApiResponse = (responseJson: any) => {
    if(responseJson.data) {
      const { posts, selectedPostId } = this.state
      const { data: {  likeable_type, likeable_id } } = responseJson
      if(likeable_type === configJSON.PostClass) {
        const updatedPosts = posts.map((post) => {
          const { attributes, ...rest } = post
          if(post.id === String(likeable_id)) {
            return {
              ...rest,
              attributes: {
                ...attributes,
                likes_count: attributes.likes_count - 1,
                is_liked: false
              }
            }
          }
          return post
        })
        this.setState({ posts: updatedPosts })
      } else {
        this.getPostCommentsApiCall(selectedPostId)
      }
    }
  }

  revertarchiveCommentApiResponse = (response: any) => {
    if (response) {
      if (response.message === "archive reverted successfully") {
        this.setState(
          {
            isShowAlert: true,
            showSucess: response.message,
            errorAlertMessage: false
          },
          () => {
            this.isShowCell();
          }
        );
      } else {
        this.setState(
          {
            isShowAlert: true,
            showSucess: response.message,
            errorAlertMessage: true
          },
          () => {
            this.isShowCell();
          }
        );
      }     
    }
  };

  handleCreatePostRedirection = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "NewPost");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handlePostProfileClick = (userId : number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ProfileView");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), userId);
    this.send(msg);
  }

  deleteCommentApiResponse = (response: any) => {
    if (response) {
      if (response.message === "Comment deleted.") {
        this.setState(
          {
            isShowAlert: true,
            showSucess: response.message,
            errorAlertMessage: false
          },
          () => {
            this.isShowCell();
          }
        );
        this.getPostCommentsApiCall(this.state.selectedPostId)
      } else {
        this.setState(
          {
            isShowAlert: true,
            showSucess: response.message,
            errorAlertMessage: true
          },
          () => {
            this.isShowCell();
          }
        );
      }
    }
  };

  archiveCommentApiResponse = (response: any) => {
    if (response) {
      if (response.message) {
        this.setState(
          {
            isShowAlert: true,
            showSucess: response.message,
            errorAlertMessage: false,
            achiveId: response.data.id
          },
          () => {
            this.isShowCell();
          }
        );
      }
    }
  };

  handleGetCommentsApiResponse = (response: any) => {
    if (response.data) {
      this.setState(prevState => {
        return {
          posts: prevState.posts.map(post => {
            if (Number(post.id) === response.data[0].attributes.commentable_id) {
              const { id, type, attributes } = post

              return {
                id,
                type,
                attributes: {
                  ...attributes,
                  comments: {
                    data: response.data
                  }
                }
              };
            }
            return post;
          }),
          selectedPostId: "",
          replyPostId: "",
        };
      });
    }
    this.handleSortingCommentsChange(false)
  };

  handleScrollTrendingPost = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollHeight - scrollTop <= clientHeight + 10) {
      this.setState(prevState => ({
        postTrendingPage: prevState.postTrendingPage + 1
      }));
      this.handleTrendingPostApiCall(this.state.postTrendingPage);
    }
  };

  //Eu trending post API
  handleTrendingPostApiCall = (page: number = 1) => {
    this.setState({isLoader: true});
    const euToken = window.localStorage.getItem("EUAuthToken") || '';
    this.euTrendingPostApiCallId = this.makeRequest({
      endpoint: `${configJSON.euTrendingpostEndpoint}?page=${page}`,
      method: configJSON.euTrendingPostMethod,
      headers: {
        "Content-Type": configJSON.dashboarContentType,
        token: euToken
      }
    });
  };

  handleTrendingPostAPIResponse = (response: any) => {
    if (response.posts) {
      this.setState(prevState => ({
        trendingpost: [...prevState.trendingpost, ...response.posts.data],
        isLoader: false,
      }));
    }
  };
  isVideo = (file: any) => {
    return (
      (typeof file.link === "string" && file.link.toLowerCase().endsWith(".mp4")) || file.mime_type === "video/mp4"
    );
  };

  handleResponseblock = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.dashboardPageApiCallId) {
      this.handleDashboardAPIResponse(responseJson);
    }

    if (apiRequestCallId === this.tokenUpdateApiCallId) {
      this.handleTokenUpdateApiResponse(responseJson);
    }

    if (apiRequestCallId === this.hidePostCallId) {
      this.hidePostApiResponse(responseJson);
    }

    if (apiRequestCallId === this.banUserCallId) {
      this.banUserApiResponse(responseJson);
    }
    if (apiRequestCallId === this.postCommentCallId) {
      this.postCommentApiResponse(responseJson);
    }
    if (apiRequestCallId === this.likePostApiCallID) {
      this.likePostApiResponse(responseJson);
    }

    if(apiRequestCallId === this.undoLikeApiCallId) {
      this.undoLikeApiResponse(responseJson);
    }

    if (apiRequestCallId === this.archiveCommentCallId) {
      this.archiveCommentApiResponse(responseJson);
    }

    if (apiRequestCallId === this.deleteCommenCallID) {
      this.deleteCommentApiResponse(responseJson);
    }
    if (apiRequestCallId === this.revertArchiveCommentCallID) {
      this.revertarchiveCommentApiResponse(responseJson);
    }

    if (apiRequestCallId === this.getCommentsApiCallId) {
      this.handleGetCommentsApiResponse(responseJson);
    }
    if (apiRequestCallId === this.euTrendingPostApiCallId) {
      this.handleTrendingPostAPIResponse(responseJson);
    }
    if (apiRequestCallId === this.euTrendingBrandsApiCallId) {
      if(!responseJson.errors) {
        this.setState({
          brandsIFollowTrending: responseJson?.followings?.data,
          suggestedBrandsTrending: responseJson?.suggested_brands?.data,
          top6Trending: responseJson?.top6?.data
        });
      }
    }
  };
}
// Customizable Area End
