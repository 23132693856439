// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  Typography,
  Card,
  Box,
  IconButton,
  Popover,
  List,
  ListItem,
  Modal,
  TextField,
  InputLabel,
} from "@material-ui/core";
import WebsiteController from "./WebsiteController.web";
import {
  withStyles,
  createStyles,
  Theme,
  styled,
} from "@material-ui/core/styles";
import { ThreeDotIcon, stepImageFirst, CloseIcon, EverybrandIcon } from "./assets";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Form, Formik } from "formik";
import * as yup from "yup";


const form_schema = yup.object().shape({
  webName: yup
    .string()
    .required("Name is required")
    .matches(/^[A-Za-z\s]+$/, "Name can only contain letters"),
  webUrl: yup
    .string()
    .required("URL is required")
    .matches(/^(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/, "enter a valid url"),
});

export class Websites extends WebsiteController {
  render() {
    const { classes }: any = this.props;
    return (
      <Container maxWidth={false} className={classes.websiteMainCOntainer}>
        <Snackbar
          open={this.state.isShowAlert}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            data-test-id="webUpdateAlert"
            elevation={6}
            variant="filled"
            severity={"success"}
          >
            {this.state.alertMessage}
          </MuiAlert>
        </Snackbar>
        <Box className={classes.everyBrandBox}>
        <Typography className={classes.webHeading}>Websites</Typography>
       
      <img src={EverybrandIcon} alt="" className={classes.everybrandlogo}/>
        </Box>
        <Typography className={classes.webSubHeading1}>Easily add another business you manage to your account. Click ‘Add New Website’ to get started!</Typography>
        <Button
          variant="contained"
          className={classes.addnewWebBTn}
          data-test-id="addnewWebBTn"
          onClick={this.openModal}
        >
          Add new website
        </Button>
        {this.state.websites.map((item: any) => (
          <Card
            key={item.id}
            className={classes.websiteCards}
            data-test-id="websiteCards"
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box className={classes.smileBox}>
                <img
                  src={stepImageFirst}
                  alt="simeimag"
                  style={{ height: "32px", width: "32px" }}
                />
              </Box>
              <Box sx={{ margin: "0 20px" }}>
                <Typography className={classes.cardHeading}>
                  {item.attributes.name}
                </Typography>
                <Typography className={classes.cardPara}>
                  {item.attributes.url}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                onClick={(e) => this.handleClick(e, item.id)}
                data-test-id="IconButtonMain"
              >
                <img src={ThreeDotIcon} alt="threedoticon" />
              </IconButton>
              <Popover
                data-test-id="popoverMain"
                id={String(item.id)}
                open={this.state.activePopoverId === item.id}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  data-test-id="list"
                >
                  <ListItem
                    button={true}
                    className={classes.listItemHover}
                    data-test-id="listItem1"
                    onClick={() => this.deteleWebsite(item.id)}
                  >
                    <DeleteOutlineIcon style={{ color: "red" }} />
                    <Typography>Delete</Typography>
                  </ListItem>
                  <ListItem
                    button={true}
                    className={classes.listItemHover}
                    data-test-id="listItem2"
                    onClick={() => this.openModalUpdate(item)}
                  >
                    <SentimentSatisfiedIcon />
                    <Typography>Upgrade</Typography>
                  </ListItem>
                </List>
              </Popover>
            </Box>
          </Card>
        ))}
        {!this.state.websites.length && !this.state.loading && (
          <Box>
            <Typography align="center" className={classes.noRecord}>
              No Website found
            </Typography>
          </Box>
        )}
        <Modal
          open={this.state.createWebModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="websiteCreateModal"
        >
          <ModalBody>
            <ModalTitle>
              Create WebSite
              <CloseImgIcon src={CloseIcon} alt="" onClick={this.closeModal} />
            </ModalTitle>
            <Formik
              initialValues={{
                webName: "",
                webUrl: "",
              }}
              validationSchema={form_schema}
              onSubmit={(values) => {
                this.onCreateWebsite(values);
              }}
              data-test-id="mainFormCreateWebsite"
            >
              {({ errors, handleChange, handleBlur, values }) => (
                <Form>
                  <FormControl>
                    <StyledInputLabel>Website Name</StyledInputLabel>
                    <StyledTextField
                      data-test-id="webName"
                      variant="outlined"
                      value={values.webName}
                      onChange={handleChange("webName")}
                      error={errors.webName ? true : false}
                      helperText={errors.webName}
                      onBlur={handleBlur("webName")}
                    />
                  </FormControl>
                  <FormControl>
                    <StyledInputLabel>Website URL</StyledInputLabel>
                    <StyledTextField
                      data-test-id="webUrl"
                      variant="outlined"
                      value={values.webUrl}
                      onChange={handleChange("webUrl")}
                      error={errors.webUrl ? true : false}
                      helperText={errors.webUrl}
                      onBlur={handleBlur("webUrl")}
                    />
                  </FormControl>
                  <Button
                    className={classes.saveBtnModal}
                    data-test-id="SaveBtn"
                    type="submit"
                  >
                    save
                  </Button>
                  <CancelBtn
                    data-test-id="cancelButtonCallWeb"
                    onClick={this.closeModal}
                  >
                    cancel
                  </CancelBtn>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>

        <Modal
          open={this.state.updateModal}
          onClose={this.closeModalUpdate}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="webUpdateModal"
        >
          <ModalBody>
            <ModalTitle>
              Edit WebSite
              <CloseImgIcon
                src={CloseIcon}
                alt=""
                onClick={this.closeModalUpdate}
              />
            </ModalTitle>
            <Formik
              initialValues={{
                webName: this.state.websiteName,
                webUrl: this.state.websiteUrl,
              }}
              validationSchema={form_schema}
              onSubmit={(values) => {
                this.onUpdateWebsite(values);
              }}
              data-test-id="updateWebForm"
            >
              {({ errors, touched, handleChange, handleBlur, values }) => (
                <Form>
                  <FormControl>
                    <StyledInputLabel>Website Name</StyledInputLabel>
                    <StyledTextField
                      data-test-id="updateWebName"
                      variant="outlined"
                      value={values.webName}
                      onChange={handleChange("webName")}
                      error={errors.webName ? true : false}
                      helperText={errors.webName}
                      onBlur={handleBlur("webName")}
                    />
                  </FormControl>
                  <FormControl>
                    <StyledInputLabel>Website URL</StyledInputLabel>
                    <StyledTextField
                      data-test-id="updateWebUrl"
                      variant="outlined"
                      value={values.webUrl}
                      onChange={handleChange("webUrl")}
                      error={errors.webUrl ? true : false}
                      helperText={errors.webUrl}
                      onBlur={handleBlur("webUrl")}
                    />
                  </FormControl>
                  <Button className={classes.saveBtnModal} type="submit">
                    save
                  </Button>
                  <CancelBtn
                    data-test-id="cancelButtonCallWebUpdate"
                    onClick={this.closeModalUpdate}
                  >
                    cancel
                  </CancelBtn>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}

const CloseImgIcon = styled("img")({
  position: "absolute",
  right: "-30px",
  bottom: "36px",
  cursor: "pointer",
});

const ModalBody = styled(Box)({
  position: "relative",
  backgroundColor: "#fff",
  padding: "32px 48px 24px",
  width: "480px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "0px 0px 40px 0",
  "@media (max-width: 500px)": {
    width: "auto",
  }
});

const ModalTitle = styled(Typography)({
  fontSize: "20px",
  position: "relative",
  marginBottom: "38px",
  display: "flex",
});

const ResetModalTitle = styled(Typography)({
  fontSize: "20px",
  position: "relative",
  marginBottom: "38px",
  display: "flex",
  justifyContent: "center",
});

const FormControl = styled(Box)({
  marginBottom: "40px",
});

const StyledInputLabel = styled(InputLabel)({
  marginBottom: "8px",
  color: "#000",
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#979797",
    },
    "&:hover fieldset": {
      borderColor: "#979797",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#979797",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffca29",
    },
    "&.Mui-error fieldset": {
      borderColor: "red",
    },
  },
});

const SaveBtn = styled("button")({
  backgroundColor: "#ffca29",
  padding: "16px 0",
  color: "black",
  textTransform: "capitalize",
  display: "block",
  width: "100%",
  border: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
  marginBottom: "40px",
});
const CancelBtn = styled("button")({
  backgroundColor: "black",
  padding: "16px 0",
  color: "white",
  textTransform: "capitalize",
  width: "100%",
  border: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
});

const styles = (theme: Theme) =>
  createStyles({
    everyBrandBox:{
      display:"flex",
      flexDirection:"row",
      justifyContent:"space-between",
      width:"100%"
    },
    saveBtnModal: {
      backgroundColor: "#ffca29",
      padding: "16px 0",
      color: "black",
      textTransform: "capitalize",
      display: "block",
      width: "100%",
      border: "none",
      borderRadius: "12px",
      fontSize: "16px",
      fontWeight: 700,
      cursor: "pointer",
      marginBottom: "40px",
    },
    cancelBtnModal: {
      backgroundColor: "black",
      padding: "16px 0",
      color: "white",
      textTransform: "capitalize",
      width: "100%",
      border: "none",
      borderRadius: "12px",
      fontSize: "16px",
      fontWeight: 700,
      cursor: "pointer",
    },
    modalBoxMain: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "32px 48px 24px",
      width: "480px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0px 0px 40px 0",
    },
    noRecord: {
      color: "#64748B",
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "18px",
    },
    listItemHover: {
      width: "150px",
      "&:hover": {
        backgroundColor: "#FFC629", // Example hover background color
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          // Targets both Typography and Icons inside the ListItem
          color: "red", // Example hover text/icon color
        },
      },
    },
    smileBox: {
      backgroundColor: "#FFC629",
      borderRadius: "50%",
      padding: "0 7px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    websiteCards: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderRadius: "8px",
      background: "#FFF",
      padding: "24px",
      margin: "10px 0",
      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    addnewWebBTn: {
      padding: "10px 48px 10px 50px",
      borderRadius: "8px",
      background: "#FFC629",
      margin: "20px 0",
      color: "#0F172A",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
    },
    websiteMainCOntainer: {
      padding: "50px",
      [theme.breakpoints.down(600)]: {
        padding: "24px"
      },
    },
    webHeading: {
      color: "#000",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      [theme.breakpoints.down(600)]: {
        display: "none"
      },
    },
    everybrandlogo: {
      [theme.breakpoints.down(600)]: {
        display: "none"
      },
    },
    webSubHeading1:{
      color: "#000",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      [theme.breakpoints.down(600)]: {
        fontWeight: "normal"
      },
    },
    cardHeading: {
      color: "#0F172A",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
    },
    cardPara: {
      color: "#64748B",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
  });

export default withStyles(styles)(Websites);
// Customizable Area End
