import * as React from 'react';
import {OutlinedInput, MenuItem, FormControl, Select, styled} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    formControl: {
      width: 300,
    },
    select: {
      height: 48,
      width: 300,
      border: 'none'
    },
  });

interface Props {
    onChange: (event: React.ChangeEvent<{ value: unknown; }>) => void;
    selectedPlatforms: string[];

}

const platforms = ["youtube", "linkedin", "tiktok", "x", "instagram", "facebook"];

const MultiSelectPlatform: React.FC<Props> = ({onChange, selectedPlatforms}) => {
    const classes = useStyles();

  return (
    <div>
        <FormControl className={classes.formControl}>
          <MuiSelect
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={selectedPlatforms}
            onChange={onChange}
            input={<OutlinedInput/>}
            MenuProps={{
              getContentAnchorEl: null,
          }}
            className={classes.select}
            >
            {platforms.map((platform) => (
                <MenuItem key={platform} value={platform}>
                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>

    </div>
  );
}

export default MultiSelectPlatform;

const MuiSelect = styled(Select)({
  "& .MuiSelect-root": {
    width: "280px",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Inter, 'sans-serif'",
    color: "black",
    border: "none",
    outline: "none",
  },
  "& .MuiMenuItem-root": {
      color: "white",
      background: 'black'
  },
  "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "black",
      color: "white",

      "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
  },
  "& .MuiMenu-paper": {
    background: "black"
  },
  "& .MuiList-root .MuiMenu-list .MuiList-padding":{
    background: "black !important",
    color: "white !important"
  }
})