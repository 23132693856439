import React from "react";
import NavigationMenuEUWeb from "../../../components/src/NavigationMenuEU.web";
import { CommentIconWhite, HomeIcon,imageVector, sendMedssageIcon, uploadIcon } from "./assets";
import { Box, Button, TextField, Typography, IconButton, Popover, Snackbar, Divider, styled } from "@material-ui/core";
import TopActiveUsers from "./TopActiverUsers.web";
import SuggestedBrands from "../../../components/src/SuggestedBrands";
import LikeButton from "../../../components/src/LikeButton";
import EuDashboardController from "./EuDashboardController.web";
import ImageButton from "../../../components/src/ImageProfile";
import PostInfo from "../../../components/src/PostInfo";
import SettingsButton from "../../../components/src/SettingsButton";
import CustomLoader from "../../../components/src/CustomLoader.web"

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { Alert} from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import Poll from "./Poll.web";
import MobileNavEndUser from "./MobileNavEndUser.web";






export default class EuDashboard extends EuDashboardController {


  

 
  handleFileRender(postFile: {
    link: string
  }, index: number) {
    return (
      this.checkIsVideo(postFile) ? (
        <video
          ref={this.state.videoRefsHome[index]}
          src={postFile.link}
          style={styles.image}
          controls
        />
      ) : (
        <img
          src={postFile.link}
          alt=""
          style={styles.image}
        />
      )
    )
  }

  handleDescription=(data:string)=>{
    return data ? data : ""
  }
  renderUploadedReplyImage() {
    const { uploadedReplyImage } = this.state;
  
    if (!uploadedReplyImage) return null;
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', position: 'relative' }}>
        <img
        data-test-id="uploaded-image-preview"
          src={uploadedReplyImage}
          alt="Uploaded preview"
          style={{
            maxWidth: '50px',
            maxHeight: '50px',
            marginRight: '10px',
            borderRadius: '4px',
          }}
        />
        <button
          data-test-id="cancelUploadReply"
          onClick={this.cancelUploadReplyImage}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            color: 'red',
            fontSize: '20px',
            position: 'absolute',
            top: '-15px',
            right: '290px',
          }}
          aria-label="Cancel upload"
        >
          &times;
        </button>

      </div>
    );
  }

  renderUploadedImage() {
    const { uploadedImage } = this.state;
  
    if (!uploadedImage) return null;
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', position: 'relative' }}>
        <img
          src={uploadedImage}
          alt="Uploaded preview"
          style={{
            maxWidth: '50px', 
            maxHeight: '70px',
            marginRight: '10px', 
            borderRadius: '4px', 
          }}
        />
        <button
          data-test-id="cancelUpload"
          onClick={() => this.setState({ uploadedImage: null, uploadedImageFile: null })}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            color: 'red', 
            fontSize: '20px',
            position: 'absolute',
            top: '-12px', 
            right: '404px', 
          }}
          aria-label="Cancel upload"
        >
          &times;
        </button>
      </div>
    );
  }

  renderComment = (comment:any,postId:any) => {
    const full_name = comment.attributes.account.data.attributes.full_name;
    const first_name = comment.attributes.account.data.attributes.first_name;
    const profile_pic = comment.attributes.account.data.attributes.profile_pic;
    const { comment: commentText = "", created_at = "" } = comment.attributes || {};
    const formattedTimeAgo = this.timeAgo(created_at);
    const replies = Array.isArray(comment.attributes.replies?.data) ? comment.attributes.replies.data : [];
    const imageUrl = comment.attributes.files?.length > 0 ? comment.attributes.files[0].url : null;
    return (
      <Box key={comment.id} style={{ display: "flex", justifyContent: "space-between", padding: '10px 0' }}>
        <Box>
          <Box data-test-id="commentContainer" style={styles.commentUserContainer}>
            <div style={{ display: 'flex', gap: '6px' }}>
              <div  data-test-id="profilePic">
                {profile_pic ? (
                  <img src={profile_pic.url} style={styles.userIcon} />
                ) : (
                  <Box style={styles.initialIcon}></Box>
                )}
              </div>
              <div>
                <div>
                  <div style={{ display: 'flex', justifyContent: "space-between", fontSize: '14px', padding: "10px 0px 0px 10px" }}>
                    <div data-test-id="fullName" style= {styles.nameComment}>{full_name || first_name || "Anonymous"}</div>
                    <div style={styles.nameCommentTime} >{formattedTimeAgo}</div>
                  </div>
                  <p data-test-id="commentText" style={styles.userName}>{commentText}</p>
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Uploaded"
                      style={{ maxWidth: '100%', borderRadius: '8px' }} 
                    />
                  )}
                </div>
                <div style={{ display: 'flex', gap: '8px', fontSize: '14px', alignItems: 'center' }}>
                  <Box  data-test-id="commentLike" onClick={() => comment.attributes.already_liked ? this.undoLike(comment.id, comment.attributes.commentable_id) : this.handleLike(comment.id, comment.attributes.commentable_id)}>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Box>
                        {comment.attributes.already_liked ? (
                          <FavoriteIcon style={{ color: 'red' }} />
                        ) : (
                          <FavoriteBorderIcon style={{ color: 'inherit' }} />
                        )}
                      </Box>
                      <Box>
                        <Typography variant="caption" style={{ marginLeft: '8px' }}>
                          {`${comment.attributes.likes_count} likes`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                 
                  <p data-test-id="commentReply"  onClick={() => this.handleReplyClick(comment.id)}>Reply</p>
                  <p>
                    {`View ${replies.length} ${replies.length === 1 ? 'reply' : 'replies'}`}
                  </p>
                  <KeyboardArrowDown  data-test-id="replyVisibility"  onClick={() => this.toggleRepliesVisibility(comment.id)} />
                </div>
                {this.state.replyToCommentId === comment.id && (
                <div style={styles.replyInput}>
                  <input
                    type="file"
                    id="fileInputReply"
                    accept="image/*"
                    data-test-id="input-tag"
                    onChange={this.handleReplyImageUpload}
                    style={{ display: "none" }} 
                    ref={this.fileInputReply} 
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {this.renderUploadedReplyImage()}
                    <div style={{ display: "flex" }}>
                     <label htmlFor="fileInputReply">
                     <div data-test-id="fileInput" style={{ marginRight: "10px" }} onClick={this.handleFileInputClick}>
                        <img src={uploadIcon} alt="upload icon" />
                      </div>
                     </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        style={styles.reply}
                        value={this.state.replyText}
                        onChange={this.handleReplyChange}
                        placeholder="Write a reply..."
                      />
                      <Box
                        color="primary"
                        style={{ marginLeft: "7px" }}
                        data-test-id="sendReplyIcon"
                        onClick={() => this.handleReplySubmit(comment.attributes.commentable_id)}
                      >
                        <img src={sendMedssageIcon} alt="sendIcon" />
                      </Box>
                      <p
                        data-test-id="sendReplyCancel"
                        onClick={() => this.setState({ replyToCommentId: null, replyText: '' })}
                        style={{ marginLeft: '10px', cursor: 'pointer', color: '#007BFF' }}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              )}
  
                {/* Replies Section */}
                {this.renderReplies(replies, comment)}
              </div>
            </div>
          </Box>
        </Box>
        <Box>
          <IconButton data-test-id="openPopover" onClick={(event) => this.openActionPopover(event, postId, comment.id)}>
            <MoreHorizIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };


  renderReplies = (replies: any[], comment: { id: string | number; }) => {
    const isRepliesVisible = this.state.repliesVisible?.[comment.id];
  
    return (
      <>
        {isRepliesVisible && (
          <Box style={{ marginLeft: "30px", marginTop: "10px" }}>
            {replies.map((reply) => (
              <Box key={reply.id} style={{ display: "flex", gap: "10px", padding: "5px 0" }}>
                <div>
                  <img data-test-id="replyImage" src={reply.attributes?.account?.data.attributes.profile_pic?.url || ""} style={styles.userIcon} />
                </div>
                <div style={{ backgroundColor: '#f1f1f1', borderRadius: '8px', padding: '10px' }}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>
                      {reply.attributes?.account?.data.attributes.full_name || reply.attributes?.account?.data.attributes.first_name || "Anonymous"}
                    </span>
                    <span style={{ fontSize: '12px', color: '#888' }}>
                      {this.timeAgo(reply.attributes.created_at)}
                    </span>
                  </Box>
                  <Box style={{ display: "flex" }}>
                    <Box>
                      <p>{reply.attributes.comment}</p>
                      {reply.attributes?.files?.length > 0 && reply.attributes.files.map((file: { url: string | undefined; }) => (
                        <img
                        data-test-id="replyimg"
                          src={file.url} 
                          alt="Uploaded"
                          style={{ maxWidth: '100%', borderRadius: '8px' }} 
                        />
                      ))}
                    </Box>
                    <Box>
                      <IconButton>
                        <MoreHorizIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </div>
              </Box>
            ))}
          </Box>
        )}
      </>
    );
  };

  renderPoll(poll:any, poll_options:any, account_profile: { url: string | undefined; }, postId: string) {
    return (
      poll && (
        <Poll
          data-test-id="poll"
          poll={poll}
          poll_options={poll_options}
          accountProfile={account_profile?.url}
          handleVote={(optionId: number) => this.props.handleVote(optionId, postId)}
          handleDeleteVote={(optionId: number) =>
            this.props.handleDeleteVote(optionId, postId)
          }
        />
      )
    );
  }

  render() {
     
    const { navigation } = this.props
    const { posts, suggestedBrandsHome, top6, anchorEl,allCommentsData } = this.state;
  
 
    
    const open = Boolean(anchorEl);

 

    return (
      <>
        <div style={styles.root} data-test-id="eu-home">
          <NavigationMenuEUWeb brands={this.state.brandsIFollowHome} />
          <div ref={this.homeRef} style={styles.mainContainer} data-test-id="eu-home-wrapper">
            <ParentBox style={styles.contentContainer}>
              <ImageBox className="imagecontainerdiv" style={styles.imagesContainer} data-test-id="eu-home-post-wrapper" onScroll={this.handleScrollHomePost}>
                <Typography variant="h6" style={styles.heading}>
                {this.state.isDashboard ? (
  <>
    <img src={HomeIcon} alt="Home Icon" style={styles.title} />
    HOME
  </>
) : (
  <>
    <img src={imageVector} alt="Trending Icon" style={styles.title} />
    TRENDING
  </>
)}

                </Typography>
                <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          accept="image/*"
          onChange={this.handleFileChange}
        />
                {posts &&
                  posts.map((post, index) => {
                    const postFile = post.attributes.files
                      ? post.attributes.files[0]
                      : null;
                    const amountLike = post.attributes.likes_count
                      ? post.attributes.likes_count
                      : 0;
                    const isLiked = post.attributes.is_liked;
                    const commentsCount = post.attributes.comments_count || 0;
                    const poll = post.attributes.poll
                    const poll_options = post.attributes.poll_options
                    const account_profile = post.attributes.account_profile
                    

                    return (
                      postFile && (
                        <Box key={post.id} style={styles.imageContainer}>
                          {postFile ? this.handleFileRender(postFile, index) : (
                            ""
                          )}

                          <div style={{position: 'relative'}}>
                          <PostInfo
                            title={post.attributes.body as string}
                           
                            description={this.handleDescription(post.attributes.description)}
                          />
                          </div>
                          <div style={{ position: 'relative' }}>
                            {post.attributes.account_profile &&
                              <ImageButton
                                imageUrl={post.attributes.account_profile.url}
                              />
                            }
                          </div>
                          <Box style={{ position: "relative" }}>
                            <div style={{ position: 'relative' }}>
                              <LikeButton
                                data-test-id="likeBtnWrapper"
                                amount={amountLike}
                                isLiked={isLiked}
                                handleLikes={() => this.handlePostLikes(post.id)}
                              />
                            </div>
                            <Box style={styles.commentIcon} data-test-id="comment-icon-box" onClick={() => this.toggleCommentBox(post.id)}>
                              <IconButton style={{ color: '#FFFFFF', display: 'flex', flexDirection: 'column' }}>
                                <span style={{ display: 'flex', flexDirection: "column" }}>
                                  <IconButton aria-label="post-comments">
                                    <img
                                      data-test-id="commentIconImg"
                                      src={CommentIconWhite}
                                      alt="Comment"
                                      style={styles.commentImg}
                                    />
                                  </IconButton>
                                  <Typography style={styles.commentCount}>
                                    {commentsCount || 0}
                                  </Typography>
                                </span>

                              </IconButton>
                            </Box>
                            <div style={{ backgroundColor: 'red' }}>
                              <SettingsButton />
                            </div>
                          </Box>

                          {this.state.currentCommentsPostId === post.id && this.state.allCommentsData  && (
                           
                            <Box style={styles.commentBox}>
                              {/* Comments List */}
                              <Box style={styles.commentsList}>
                                <Divider />
                                <div style={{display: 'flex',flexDirection: 'column',alignItems: 'end'}}>
                                <CloseIcon data-test-id="closeicon" onClick = {() => this.toggleCommentBox(null)} style={{marginRight:"15px" ,marginTop:"10px"}} />
                                <div style={{ display: 'none', justifyContent: 'right', textAlign: 'center', alignItems: 'center', marginRight: "20px" }}>
                                  
                                  <h4>All Comments</h4>
                                  <KeyboardArrowDown />
                                </div>
                                </div>
                                <Box>
                    {this.renderPoll(
                      poll,
                      poll_options,
                      account_profile,
                      post.id
                    )}
                  </Box>
                               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                               <ul style={{ position: 'relative', overflowX: 'hidden', width: '100%', msOverflowX: 'hidden',margin:"0",padding:"0" }}>
                               
                               {allCommentsData?.data && allCommentsData.data.length > 0 ? (
                                allCommentsData.data.map((comment) => this.renderComment(comment, post.id)) 
                              ) : (
                                <Typography variant="body2" color="textSecondary">
                                  No comments yet.
                                </Typography>
                              )}

                                </ul>
                               </div>
                              </Box>
                              <Box style={styles.commentInputContainer}>
                              <input
                                  type="file"
                                  id="fileInput"
                                  data-test-id="input-tag1"
                                  accept="image/*"
                                  onChange={this.handleImageUpload}
                                  style={{ display: "none" }} 
                                
                                  ref={this.fileInput} 

                                />



                              <div style={{display: 'flex', flexDirection: 'column', width: "100%"}}>

                      {this.renderUploadedImage()}
              <div style={{display:"flex"}}>
              <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
              <div data-test-id="fileInput" style={{marginRight:"10px"}} onClick={this.handleFileInputClick1}>
                        <img src={uploadIcon} alt="upload icon" />
                      </div>
              </label>

                                <TextField
                                  data-test-id="commentInputContainer"
                                  variant="outlined"
                                  placeholder="Add a Comment"
                                  fullWidth
                                  multiline
                                  rows={2}
                                  value={this.state.localCommentText}
                                  onChange={this.handleLocalCommentTextChange}
                                 
                                />
                                <Box
                                  color="primary"
                                  style={{ marginLeft: "7px" }}
                                  data-test-id="sendIcon"
                                  onClick={() => this.handlePostComment(post.id)}
                                >
                                  <img src={sendMedssageIcon} alt="sendIcon" />
                                </Box>
                                </div>
                                </div>
                              </Box>
                            </Box>
                          )}
                          


                        </Box>
                      )
                    );
                  })}
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={this.closeActionPopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                 
                >
                  <Box style={{ display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
                    <Button data-test-id="deleteComment" onClick={() => this.handleDelete()}
                      style={{ backgroundColor: 'transparent', transition: 'background-color 0.3s', textTransform: 'capitalize' }}
                      onMouseEnter={e => e.currentTarget.style.backgroundColor = '#FFC629'}
                      onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}>Delete</Button>
                  </Box>
                </Popover>

                <CustomLoader loaderSize={24} isLoading={this.state.isEUHomePostLoading} />
              </ImageBox>
              <Box style={styles.sideContainer}>
                <Box>
                  <TopActiveUsers
                    navigation={navigation}
                    top6={top6}
                  />
                </Box>
                <Box>
                  <SuggestedBrands
                    brands={suggestedBrandsHome}
                    followBrand={this.followBrandApi}
                  />
                </Box>
              </Box>
            </ParentBox>
          </div>
          <Snackbar
          data-test-id="dialogDelete"
          open={this.state.openSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity}>
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        
      </div >
      <div>
        <MobileNavEndUser navigation={navigation} />
      </div>
      </>
    );
  }
}

const styles = {
  root: {
    backgroundColor: "#f1f4f9",
    minHeight: "100vh",
  } as React.CSSProperties,

  title: {
    marginRight: "15px"
  },

  mainContainer: {
    width: "100vw",
    maxWidth: "1240px",
    margin: "15px auto",
  } as React.CSSProperties,

  contentContainer: {
    padding: "0px 20px",
    display: "flex",
    gap: "15px",
    justifyContent: "center",
  },

  imagesContainer: {
    padding: "10px 200px",
    maxWidth: '422px',
     backgroundColor: "#FFFFFF",
    maxHeight: "788px",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    scrollSnapType: "y mandatory",
    scrollbarWidth: "none"
  } as React.CSSProperties,
 

  sideContainer: {
    width: "30%",
  } as React.CSSProperties,

  heading: {
    scrollSnapAlign:"center",
    margin: "30px 0 -40px 45px",
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    display: "flex"
  },

  imageContainer: {
    scrollSnapAlign:"start",
    scrollMarginTop: "20px",
    position: "relative",
    top: '80px',
    margin: "10px",
    display: "block",
  } as React.CSSProperties,

  image: {
    borderRadius: "10px",
    width: "100%",
    height: "calc(100vh - 200px)",
  },

  commentIcon: {
    position: "absolute" as const,
    bottom: '50px',
    right: "5px"
  },

  commentImg: {
    width: "24px",
    height: "24px"
  },

  commentBox: {
    maxHeight: '450px',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    padding: '10px',
    borderTop: '1px solid #ccc',
    zIndex: 999,
    position:"absolute" as 'absolute',
    borderRadius:"8px"
  },

  commentsList: {
    maxHeight: '300px',
    overflowY: 'auto' as const,
    overflowX:"hidden" as const
  },

  commentItem: {
    padding: '10px',
    backgroundColor: '#f1f1f1',
    borderRadius: '8px',
    marginBottom: '10px',
  },

  commentText: {
    color: '#000000',
    fontSize: '14px',
    maxWidth: '85%',
    wordWrap: 'break-word' as const,
  },

  commentInputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },

  sendIconButton: {
    marginLeft: '10px',
  },

  moreVertIcon: {
    color: '#888888',
  },

  userIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    objectFit: 'cover' as const,
    flexShrink: 0,
    display: 'block',
    padding: '10px',
  },

  initialIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#888888',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 'bold' as const,
  },

  userName: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#000',
    fontFamily:"Inter",
    paddingRight: '10px',
    paddingLeft: '12px',
    paddingBottom: "10px",
    borderRadius: '6px',
  },

  commentUserContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  commentCount: {
    fontSize: '14px',
    color: '#FFFFFF',
  },
  comment: {
    marginBottom: "1em"
  },
  
  commentActions: {
    display: "flex",
    gap: "1em",
    marginTop: "0.5em",
  },
  
  replyInput: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5em",
  },
  
  reply: {
    fontSize: "0.9em",
    color: "#555"
  },

  navigationMenu: {
    transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
    transform: "translateY(0)",
    opacity: "1"
  },
  
  slideUp: {
    transform: "translateY(-100%)",
    opacity: "0"
  },
  nameComment:{
    fontSize:"14px",
    fontFamily:"Inter"
  },
  nameCommentTime:{
    fontSize:"14px",
    fontFamily:"Inter",
    marginRight:"5px"
  }
};

const ImageBox = styled(Box)({
  padding: "10px 200px",
  width: "100%",
  backgroundColor: "#FFFFFF",
  maxHeight: "900px",
  overflowY: "scroll",
  display: "flex",
  flexDirection: "column",
  scrollSnapType: "y mandatory",
  scrollbarWidth: "none",
   /* Webkit-specific scrollbar hiding */
   "::-webkit-scrollbar": {
    width: "0px",  /* Hide vertical scrollbar */
    height: "0px" /* Hide horizontal scrollbar */          /* Hide horizontal scrollbar */
  },
})

const ParentBox = styled(Box)({
  '@media(max-width: 960px)': {
    display: 'flex',
    flexDirection: 'column',
    '& .imagecontainerdiv': {
      paddingInline: '0px !important',
      maxWidth: '100% !important'
    }
  }
})
