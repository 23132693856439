// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography, Button } from "@material-ui/core";
import { reelIcon } from "./assets";
import AnalyticsReelsController, {
  VideoViews,
} from "./AnalyticsReelsController.web";
import moment from "moment";

export class AnalyticsReels extends AnalyticsReelsController {
  render() {
    const { classes, videos } = this.props;
    const { videoAnswers, selectedVideoId, currentVideoView } = this.state;

    return (
      <Box data-test-id="reelsAnalytics" className={classes.mainContainer}>
        <Box className={classes.reelsStatsContainer}>
          <Box className={classes.reelsStatsHeader}>
            <img
              className={classes.reelsIcon}
              src={reelIcon}
              alt="reels icon"
            />
            <Typography className={classes.reelsStatsTitle}>
              EveryReel
            </Typography>
          </Box>

          <Box className={classes.reelsSortControls}>
            <Button
              data-test-id="resultsButton"
              className={classes.reelsSortButton}
              onClick={() => this.setVideoView(VideoViews.RESULTS)}
              style={{
                backgroundColor:
                  currentVideoView === VideoViews.RESULTS ? "#000" : "#F8FAFC",
                color:
                  currentVideoView === VideoViews.RESULTS ? "#FFF" : "#000",
              }}
            >
              Results
            </Button>
            <Button
              data-test-id="leadsButton"
              className={classes.reelsSortButton}
              onClick={() => this.setVideoView(VideoViews.LEADS)}
              style={{
                backgroundColor:
                  currentVideoView === VideoViews.LEADS ? "#000" : "#F8FAFC",
                color: currentVideoView === VideoViews.LEADS ? "#FFF" : "#000",
              }}
            >
              Leads
            </Button>
            <Button
              id="clicksButton"
              data-test-id="clicksButton"
              className={classes.reelsSortButton}
              onClick={() => this.setVideoView(VideoViews.LINK_CLICKS)}
              style={{
                backgroundColor:
                  currentVideoView === VideoViews.LINK_CLICKS
                    ? "#000"
                    : "#F8FAFC",
                color:
                  currentVideoView === VideoViews.LINK_CLICKS ? "#FFF" : "#000",
              }}
            >
              Link Clicks
            </Button>
          </Box>

          <Box className={classes.videosList}>
            {Boolean(videos?.length) &&
              videos.map((video) => (
                <Box
                  data-test-id={`video-${video.id}`}
                  key={video.id}
                  className={classes.videoItem}
                  style={{
                    backgroundColor:
                      selectedVideoId === video.id ? "#F1F5F9" : "transparent",
                  }}
                  onClick={() => this.setSelectedVideoId(video.id)}
                >
                  <Box className={classes.videoThumbnail}>
                    <img src={video.url} alt="video thumbnail" />
                  </Box>

                  <Typography className={classes.videoTitle}>
                    {video.title}
                  </Typography>

                  <Typography className={classes.videoDate}>
                    {moment(video.createdAt).format("DD-MM-YYYY")}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>

        <Box className={classes.videoAnswersContainer}>
          <Typography className={classes.videoAnswersTitle}>
            Video Answers
          </Typography>

          <Box className={classes.answersStatsContainer}>
            {Boolean(videoAnswers.length) &&
              videoAnswers.map((answer) => (
                <Box key={answer.key} className={classes.answerContainer}>
                  <Box className={classes.answerLetter}>{answer.key}</Box>

                  <Box className={classes.answerBar}>
                    <Box
                      className={classes.answerBarVolume}
                      style={{ width: `${answer.valuePercentage}%` }}
                    />

                    <Box className={classes.answerDescriptionContainer}>
                      <Typography className={classes.answerTitle}>
                        {""}
                      </Typography>

                      <Box className={classes.answerStatsContainer}>
                        <Typography
                          className={classes.answerPercent}
                        >{`${answer.valuePercentage}%`}</Typography>
                        <Typography className={classes.answerValue}>
                          {`${answer.value} Clicks`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      width: "100%",
      alignItems: "stretch",
      justifyContent: "center",
      gap: "27px",
      [theme.breakpoints.down(800)]: {
        flexWrap: "wrap",
      }
    },

    reelsStatsContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexBasis: "440px",
      backgroundColor: "#fff",
      borderRadius: "20px",
      padding: "43px 20px 34px",
      gap: "10px",
    },

    reelsStatsHeader: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "21px",
      alignItems: "center",
      justifyContent: "flex-start",
    },

    reelsIcon: {
      width: "43px",
    },

    reelsStatsTitle: {
      fontFamily: "Inter",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },

    reelsSortControls: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "8px",
      paddingTop: "29px",
    },

    reelsSortButton: {
      display: "flex",
      height: "32px",
      padding: "6px 12px 6px 8px",
      borderRadius: "26px",
      textTransform: "none",
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "22px",
    },

    videosList: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "49px",
    },

    videoItem: {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-around",
      height: "76px",
      padding: "16px 8px",
      gap: "24px",
      cursor: "pointer",
    },

    videoThumbnail: {
      display: "flex",
      width: "43px",
      height: "43px",

      "& > img": {
        objectFit: "cover",
      },
    },

    videoTitle: {
      display: "flex",
      alignItems: "center",
      width: "127px",
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 700,
      color: "#0F172A",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    videoDate: {
      display: "flex",
      alignItems: "center",
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 700,
      color: "#0F172A",
      opacity: 0.5,
      [theme.breakpoints.down(500)]: {
        display: "none"
      }
    },

    videoAnswersContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexBasis: "684px",
      backgroundColor: "#fff",
      borderRadius: "20px",
      padding: "47px 95px 46px",
      gap: "100px",
      [theme.breakpoints.down(800)]: {
        padding: "40px 20px"
      }
    },

    videoAnswersTitle: {
      fontFamily: "Inter",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
      [theme.breakpoints.down(800)]: {
        fontSize: "18px"
      }
    },

    answersStatsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "25px",
    },

    answerContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "19px",
      alignItems: "center",
    },

    answerLetter: {
      display: "flex",
      flexShrink: 0,
      alignItems: "center",
      justifyContent: "center",
      width: "36px",
      height: "36px",
      backgroundColor: "#D9D9D9",
      borderRadius: "50%",
      fontFamily: "Inter",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
      textTransform: "uppercase",
    },

    answerBar: {
      position: "relative",
      display: "flex",
      height: "86px",
      flexBasis: "470px",
      flexGrow: 1,
      borderRadius: "50px",
      backgroundColor: "#F1F5F9",
    },

    answerBarVolume: {
      height: "100%",
      borderRadius: "50px",
      backgroundColor: "#FFC629",
    },

    answerDescriptionContainer: {
      position: "absolute",
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
      width: "100%",
      paddingLeft: "59px",
      paddingRight: "30px",
    },

    answerTitle: {
      fontFamily: "Inter",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },

    answerStatsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },

    answerPercent: {
      fontFamily: "Inter",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },

    answerValue: {
      fontFamily: "Inter",
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 700,
      color: "#000",
      opacity: 0.4,
    },
  });

export default withStyles(styles)(AnalyticsReels);
// Customizable Area End
