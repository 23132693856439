import React from "react";
// Customizable Area Start
import { Box, Modal, Switch, TextField, ThemeProvider, Typography, createTheme, styled, Popover, ListItemText, MenuItem, ListItemIcon, IconButton } from "@material-ui/core"
import { yellow } from "@material-ui/core/colors";
import UpdateBusinessHoursController, { Props, configJSON } from "./UpdateBusinessHoursController.web";
import { ThreeDotIcon, stepImageFirst, calendarIcon, addPlusIcon, EverybrandIcon, CloseIcon } from "./assets";
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MuiAlert from "@material-ui/lab/Alert"
import Snackbar from '@material-ui/core/Snackbar';
import { CustomSwitch } from '../../../components/src/CustomSwitch.web';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { MoreVertRounded } from "@material-ui/icons";
// Customizable Area End

class UpdateBusinessHours extends UpdateBusinessHoursController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    addHolidayModal = () => {

        const dateStartTime = this.state.holidayStartTime ? moment(this.state.holidayStartTime).format("hh:mm A") : undefined;


        const dateEndTime = this.state.holidayEndTime ? moment(this.state.holidayEndTime).format("hh:mm A") : undefined;

        let formattedDate = '';
        if (this.state.holidayDate) {
            const originalDate = moment(this.state.holidayDate, 'YYYY-MM-DD');
            if (originalDate.isValid()) {
                formattedDate = originalDate.format('MM/DD/YYYY');
            } else {
                console.error('Invalid date format:', this.state.holidayDate);
            }
        }
        // Convert selected start time to minutes for comparison


       


      
       
        return (
            <>
                <ModlaContainer
                    data-test-id="closeHolidayModalCallId"
                    open={this.state.holidayModal}
                    onClose={this.closeHolidayModal}
                >
                    <ModalBox>
                        <div className="holiday-form">
                                <ModalHeading component={"h4"}>
                                    Add Holiday
                                </ModalHeading>
                                <InputLabelBox>
                                    <InputLabel>Holiday Name</InputLabel>
                                    <ModalInputBox
                                        data-test-id="holidayNameCallId"
                                        variant="outlined"
                                        placeholder="Name of your Holiday"
                                        value={ this.state.holidayName}
                                        onChange={this.onHolidayNameChange}
                                        error={this.state.holidayNameErr ? true : false}
                                        helperText={this.state.holidayNameErr}
                                        inputProps={{
                                            style: {
                                                height: "35px",
                                                color: "#64748B",
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "22px",
                                            },

                                        }}
                                    />
                                </InputLabelBox>
                                <InputLabelBox>
                                    <InputLabel>Type your date</InputLabel>

                                    <ModalInputBoxDatePicker

                                        data-test-id="holidayDateCallId"
                                       value={formattedDate}
                                       minDate={new Date()}
                                        onChange={this.onHolidayDateChange}

                                        placeholderText="mm/dd/yyyy"
                                    />
                                </InputLabelBox>
                                <InputLabelBox>
                                    <InputLabel>Times they are open</InputLabel></InputLabelBox>
                                <ModalTimeInputBox>
                                    <InputLabelBox>
                                        <InputLabel>Start</InputLabel>
                                        <StyledDatePicker
                                            // selected={this.state.holidayStartTime}  
                                            onChange={this.onStartTimeChange}
                                            value={dateStartTime}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            data-test-id="startHolidayTime"
                                            placeholderText="hh:mm"
                                        />
                                    </InputLabelBox>
                                    <InputLabelBox>
                                        <InputLabel>End</InputLabel>

                                        <StyledDatePicker
                                            onChange={this.onEndTimeChange}
                                            // selected={this.state.holidayEndTime}  
                                          value={dateEndTime}
                                          
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            minDate={this.state.holidayStartTime}
                                            excludeTimes={this.state.disabledTimes}
                                            placeholderText="hh:mm"
                                            
                                            dateFormat="h:mm aa"
                                            data-test-id="EndHolidayTime"
                                        />
                                    </InputLabelBox>
                                </ModalTimeInputBox>
                                <ModalHeading component={"h4"}>
                                    After hours response
                                </ModalHeading>
                                <ModalInfoText component={"p"}>
                                    <div className="after-tomorrow-description" style={{
                                        display: "flex",
                                        padding: "12px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "3px",
                                        border: "1px solid #DADADA",
                                    }}>
                                        {configJSON.receivedModalDescription}
                                        {configJSON.tomorrowDescription}
                                    </div>
                                </ModalInfoText>
                                <div style={{ margin: "37px 0px 38px 0px" }}>
                                    <SaveButton data-test-id="saveHoliday"  onClick={this.state.isEditMode?this.confirmationModal:this.onHolidaySubmit}>
                                        Save
                                    </SaveButton>
                                </div>
                           
                        </div>
                    </ModalBox>
                </ModlaContainer >
                <Modal
                    open={this.state.openConfirmationModal}
                    onClose={this.confirmationModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    data-test-id="templateEditModal"
                >
                    <Box className={this.props.classes.EndWaitlistmodalBox}>
                        <ResetConfirmationModalTitle>
                            Are you sure you want to change Holiday hours?
                            <CloseImgIcon data-test-id="closeEndModal" src={CloseIcon} alt="" onClick={this.confirmationModal} />
                        </ResetConfirmationModalTitle>

                        <DeleteConfirmationModalFooter>
                            <DeleteButtonOfConfirmationModal data-test-id="resetButtonCall" type="button" onClick={this.confirmationModal} >Cancel</DeleteButtonOfConfirmationModal>
                            <CancelButtonOfConfirmationModal data-test-id="confirmChangeHoliday" onClick={this.updateHoliday}>Confirm</CancelButtonOfConfirmationModal>
                        </DeleteConfirmationModalFooter>
                    </Box>
                </Modal>
            </>
        )
    };

    renderHolidayList = () => {
        const open = Boolean(this.state.anchorEl)
        const id = open ? 'simple-popover' : undefined;

        return (
            <>
                <HolidayBox>
                    {
                        this.state.allHolidayData.map((item) => {
                            const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
                            const d = new Date(item.date);
                            const dayName = days[d.getDay()];
                            const dateStartTime = item.start_time ? moment(item.start_time) : null;
                            const startTime = dateStartTime ? dateStartTime.format("HH:mm") : "--"
                            const dateEndTime = item.end_time ? moment(item.end_time) : null;
                            const endTime = dateEndTime ? dateEndTime.format("HH:mm") : "--";

                            return (
                                <HolidayDetailsBox key={item.id}>
                                    <HolidayBoxIcon>
                                        <FilledSmilyImg src={stepImageFirst} alt="smiley" />
                                    </HolidayBoxIcon>
                                    <HolidayRight>
                                        <HolidayDetailBox>
                                            <HolidayHoursBoldText>{item.holiday_name}</HolidayHoursBoldText>
                                            <HolidayHoursText>{dayName} {startTime} - {dayName} {endTime}</HolidayHoursText>
                                        </HolidayDetailBox>
                                        <MoreButtonWrapper>
                                            <IconButton
                                                size="small"
                                                data-test-id="threeDot"
                                                onClick={(event: any) => this.handleClick(event, item.id)}
                                            >
                                                <MoreVertRounded />
                                            </IconButton>
                                        </MoreButtonWrapper>
                                    </HolidayRight>
                                </HolidayDetailsBox>
                            )
                        })
                    }


                </HolidayBox>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    classes={{
                        paper: this.props.classes.popOverPaper
                    }}
                >
                    <StyledMenuItem data-test-id="editModal" onClick={() => { this.holidayModalOpen(); 
                        this.handleClose();
                        this.setState({isEditMode:true}) ;}} >
                        <ListItemIcon >
                        </ListItemIcon>
                            <ListItemText primary="Edit " style={{
                                display: "flex",
                                fontSize:"14px",
                                fontWeight:400,
                                fontStyle:"normal",
                                fontFamily:"Inter",
                                color:"#0F172A"
                            }} />
                    </StyledMenuItem>
                    <StyledMenuItem data-test-id="deleteConfirmModal"  onClick={()=>{this.openEndWaitListModal();
                        this.handleClose();}}>
                        <ListItemIcon >
                            <DeleteOutlineIcon style={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText style={{
                            fontSize:"14px",
                            fontWeight:400,
                            fontStyle:"normal",
                            fontFamily:"Inter",
                            display: "flex",
                            color: "#DC2626",
                        }} primary="Delete" />
                    </StyledMenuItem>
                </Popover>
                <Modal
                    open={this.state.endWaitlistModal}
                    onClose={this.openEndWaitListModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    data-test-id="templateEditModal"
                >
                    <Box className={this.props.classes.EndWaitlistmodalBox}>
                        <ResetConfirmationModalTitle data-test-id="delete-holiday-text">
                        Are you sure you want to delete Holiday hours?
                            <HoursCloseButton data-test-id="closeEndModal" src={CloseIcon} alt="" onClick={this.openEndWaitListModal} />
                        </ResetConfirmationModalTitle>

                        <DeleteConfirmationModalFooter>
                            <DeleteButtonOfConfirmationModal data-test-id="resetButtonCall" type="button" onClick={this.openEndWaitListModal} >Cancel</DeleteButtonOfConfirmationModal>
                            <CancelButtonOfConfirmationModal data-test-id="deleteHoliday" onClick={this.deleteHlidayApi}>Confirm</CancelButtonOfConfirmationModal>
                        </DeleteConfirmationModalFooter>
                    </Box>
                </Modal>
            </>
        )
    };

    renderWorkingTable = () => (
        <WorkingHoursTimeTable>
            <thead>
                <tr>
                    <WorkingHoursTimeTableData>
                        <CalendarIcon>
                            <BsCalendar style={{ cursor: "pointer" }} src={calendarIcon} alt="" />
                        </CalendarIcon>
                    </WorkingHoursTimeTableData>
                    <WorkingHoursTimeTableData>
                        <TableText>Days Open</TableText>
                    </WorkingHoursTimeTableData>
                    <WorkingHoursTimeTableData>
                        <TableText>Start</TableText>
                    </WorkingHoursTimeTableData>
                    <WorkingHoursTimeTableData>
                        <TableText>End</TableText>
                    </WorkingHoursTimeTableData>
                </tr>
            </thead>
            <tbody>

                <>
                    {
                        this.state.getWorkingHoursData.map((item: any) => {




                            const dateStartTime = item.start_time ? moment(item.start_time) : null;
                            const startTime = dateStartTime ? dateStartTime.format("hh:mm A") : "--"
                            const dateEndTime = item.end_time ? moment(item.end_time) : null;
                            const endTime = dateEndTime ? dateEndTime.format("hh:mm A") : "--";
                           
                            return (
                                <tr key={item.id}>
                                    <WorkingHoursTimeTableData>
                                        <CustomSwitch
                                            data-testId="switch"
                                            onChange={() => this.handleChange(item, 'switch')}
                                             defaultChecked={item.active}
                                            name="checkedC" />
                                    </WorkingHoursTimeTableData>
                                    <WorkingHoursTimeTableData>
                                        <DayNameText>
                                            {item.day_name}
                                        </DayNameText>
                                    </WorkingHoursTimeTableData>
                                    <WorkingHoursTimeTableData>
                                        <TimeInputBox
                                            data-testId="startWorkingHours"

                                            onChange={(newTime) => this.handleChange({ id: item.id, time: newTime }, 'start')}



                                            value={startTime}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                        />
                                    </WorkingHoursTimeTableData>
                                    <WorkingHoursTimeTableData>
                                        <TimeInputBox
                                            value={endTime}
                                            onChange={(newTime) => this.handleChange({ id: item.id, time: newTime }, 'end')}
                                            data-testId="endWorkingHours"

                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                        />
                                    </WorkingHoursTimeTableData>
                                </tr>
                            )
                        })
                    }
                </>


            </tbody>
        </WorkingHoursTimeTable>
    );

    renderUpdateBusinessHoursContent = () => {
        return (
            <ThemeProvider theme={theme}>
                {this.addHolidayModal()}
                <UpdateTheirBusinessHours>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ paddingLeft: "53px", paddingBottom: "29px" }}>
                        <Typography className="headerText">Update their business hours</Typography>
                        <img style={{ marginRight: "80.47px", marginTop: "35px" }} src={EverybrandIcon} className="everybrandlogo" />
                    </Box>

                    
                    <Box style={{ display: "flex", gap: "58px", paddingLeft: "51px", flexWrap: "wrap-reverse" }}>
                        <Box width={"365px"}>
                        <Box style={{
                            paddingLeft: "53px",

                            paddingBottom: "28px",
                            color: "#000",
                            fontFamily: "Inter",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "32px",
                            letterSpacing: "-0.12px",
                        }} >
                            Working hours
                        </Box>
                            {this.renderWorkingTable()}
                        </Box>
                        <Box>
                            <Box style={{
                                color: "#000",
                                fontFamily: "Inter",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "32px",
                                letterSpacing: "-0.12px",
                                paddingBottom: "28px"
                            }}>
                                After hours response
                            </Box>
                            <TextAlertBox>
                                <CiFaceSmile style={{ cursor: "pointer" }} src={stepImageFirst} alt="" />
                                <AlertText>
                                    {configJSON.afterHoursDescription}
                                </AlertText>
                            </TextAlertBox>

                        </Box>
                    </Box>
                    <HrLine />
                    <HolidaySection>
                        <HolidayBoxHeader>
                            <HolidayHoursBox>
                                <HolidayHoursSwitchBoldText>Holiday Hours</HolidayHoursSwitchBoldText>
                                <HolidayHoursSwitchBtnBox>
                                    <AntSwitch
                                        data-testId="holidayHoursSwitch"
                                        onChange={this.handleChangeHolidayHoursToggle}
                                        checked={this.state.getHolidayHoursToggleData}
                                        size="medium" name="checkedC" />
                                </HolidayHoursSwitchBtnBox>
                            </HolidayHoursBox>

                            <AddHolidayBtnBox data-test-id="holidayModalCallId" onClick={() => { this.holidayModalOpen();
                            this.setState({ 
                                holidayName:"",
                                holidayDate:"",
                                holidayEndTime:"",
                                holidayStartTime:"",
                                isEditMode:false
                             }); }}>
                                <PlusIcon style={{ cursor: "pointer" }} src={addPlusIcon} alt="plus icon" />
                                <AddHolidayBoldText>Add Holiday</AddHolidayBoldText>
                            </AddHolidayBtnBox>
                        </HolidayBoxHeader>

                        {this.renderHolidayList()}
                    </HolidaySection>
                </UpdateTheirBusinessHours >
            </ThemeProvider>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <>
                <Snackbar
                    open={this.state.isShowAlert}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity={"success"}
                    >
                        {this.state.showSucess}

                    </MuiAlert>
                </Snackbar>

                {/* <Box style={{ padding: "20px",
            height: "calc(100vh - 64px)",
              overflowX: "hidden"
             }}> */}
                {this.renderUpdateBusinessHoursContent()}
                {/* </Box> */}

            </>
        )
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
const CloseImgIcon = styled("img")({
    position: "absolute",
    right: "-71px",
    bottom: "103px",
    cursor: "pointer",
    width: "24px",
    height: "26px",
    padding: "4px",
    borderRadius: "80px",
    justifyContent: "space-between",
    alignItems: "center",
    border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
    background: "#FFF",
    boxShadow: " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
})

const HoursCloseButton = styled("img")({
    position: "absolute",
    right: "-71px",
    bottom: "65px",
    cursor: "pointer",
    width: "24px",
    height: "26px",
    padding: "4px",
    borderRadius: "80px",
    justifyContent: "space-between",
    alignItems: "center",
    border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
    background: "#FFF",
    boxShadow: " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
})


const ResetConfirmationModalTitle = styled(Typography)({
    fontSize: "20px",
    position: "relative",
    marginBottom: "38px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
})

const DeleteConfirmationModalFooter = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px"
})
const CancelButtonOfConfirmationModal = styled("button")({
    backgroundColor: "#ffca29",
    padding: "16px 0",
    color: "black",
    textTransform: "capitalize",
    display: "block",
    width: "100%",
    border: "none",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
})

const DeleteButtonOfConfirmationModal = styled("button")({
    backgroundColor: "black",
    padding: "16px 0",
    color: "white",
    textTransform: "capitalize",
    width: "100%",
    border: "none",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
})
const UpdateTheirBusinessHours = styled(Box)({
    backgroundColor: "#f7fbff",
    minHeight: "100vh",
    "& .headerText": {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
        "@media (max-width: 950px)": {
            display: "none"
        }
    },
    "& .everybrandlogo": {
        "@media (max-width: 950px)": {
            display: "none"
        }
    }
});
const HolidayBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "20px"
});


const HolidayBoxIcon = styled(Box)({
    width: "38px",
    height: "39px",
    background: "#FFC629",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});
const HolidayDetailsBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff",
    padding: "22px 15px",
    gap: "20px",
    flexShrink: 0,
    alignSelf: "stretch",
    borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06),0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
});
const HolidayRight = styled(Box)({
    flex: 1,
    display: "flex",
    justifyContent: "space-between"

})
const StyledDatePicker = styled(DatePicker)({
    display: "inline-flex",
    height: "56px",
    width: "157px",
    padding: "17px 66.585px 17px 8px",
    alignItems: "center",

    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF"
})
const HolidayDetailBox = styled(Box)({
    display: "flex",
    flexDirection: "column"
})
const MoreButtonWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    "& button": {
        height: "30px",
        width: "30px"
    }
})
const TextAlertBox = styled(Box)({
    padding: "25px",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    boxShadow: "0px 1px 5px 0px rgba(128,128,128,0.75)",
    width: "317px",
    marginRight: "auto"
});

const HolidaySection = styled(Box)({
    width: "90%",
    margin: "auto",
    marginBottom: "25px"
})

const HolidayBoxHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px"
});
const TimeInputBox = styled(DatePicker)({

    display: "inline-flex",
    height: "56px",
    width: "91px",
    padding: "17px 0px 17px 8px",
    alignItems: "center",

    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF"
});
const CalendarIcon = styled(Box)({
    textAlign: "center"
});
const AddHolidayBoldText = styled(Typography)({
    color: "#000",

    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",/* 177.778% */
    letterSpacing: "-0.12px"
});
const HolidayHoursSwitchBoldText = styled(Typography)({
    color: "#000",

    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",/* 177.778% */
    letterSpacing: "-0.12px"
});
const HolidayHoursBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    columnGap: "5px",
});
const HolidayHoursSwitchBtnBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    color: "orange",
});
const AddHolidayBtnBox = styled(Box)({
    display: "flex",
    gap: "10px",
    alignItems: "center",
    cursor: "pointer"
});
const WorkingHoursTimeTable = styled("table")({
    // width: "100%",
});
const WorkingHoursTimeTableData = styled("td")({
    padding: "5px",
    textAlign: "start",
    verticalAlign: "middle",
});
const HolidayHoursBoldText = styled(Box)({
    fontWeight: 700,
    fontSize: "14px",
    color: "#0F172A",
    fontFamily: "Inter, 'sans-serif'",
    lineHeight: "22px"
});
const HolidayHoursText = styled(Box)({
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter, 'sans-serif'",
    lineHeight: "22px"
});
const DayNameText = styled(Box)({
    fontWeight: 600,

});
const TableText = styled(Box)({
    fontWeight: 600,
    textAlign: "center"
});
const AlertText = styled(Box)({

    color: "#000",

    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    paddingBottom: "43px",
    paddingTop: "20.5px"
});
const HrLine = styled("hr")({
    margin: "20px 59px",
    backgroundColor: "#DADADA",
});
const theme = createTheme({
    palette: {
        primary: {
            main: yellow[700],
        },
        secondary: {
            main: "#f44336",
        },
    },
});
const ModalBox = styled(Box)({
    backgroundColor: "#fff",
    width: "570px",
    padding: "40px 105px",
    boxSizing: "border-box",
    borderRadius: "0px 0px 24px 0px",
    overflow: "auto",
    maxHeight: "924px",
    height: "94vh",
    margin: "12px",
    "@media (max-width: 950px)": {
        padding: "24px",
    }

});
const SaveButton = styled("button")({
    backgroundColor: "#f9bb21",
    width: "100%",
    padding: "10px 0px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    color: "#0F172A",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
});
const ModalInfoText = styled(Box)({
    color: "#000",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
});
const InputLabel = styled("label")({
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px"
});
const ModalInputBox = styled(TextField)({
    height: "56px",
    // "input[type='time']::-webkit-calendar-picker-indicator ":{
    //     background: "none !important",
    //     display: "none !important"
    //   },
    "& input[type='time']::-webkit-calendar-picker-indicator": {
        // display: "none !important"
        background: "none !important",
        colo: "white !important"

    },
    "& fieldset": {
        borderColor: "#CBD5E1 !important",
        borderRadius: "8px !important",
    },
    "& .Mui-focused fieldset": {
        borderColor: "#CBD5E1 !important",
        borderRadius: "8px !important",
    },
    "input": {
        cursor: "pointer !important" /* Change cursor to pointer to indicate clickable */
    },
    "& .MuiInputBase-root input": {
        backgroundColor: "red !important",
        cursor: "pointer !important"
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "lightblue !important"
    },
    "& input['date']::placeholder": {
        color: "red"
    },
    "& input::placeholder": {
        color: "#64748B",
        opacity: 1
    },

});
const ModalInputBoxDatePicker = styled(DatePicker)({
    display: "inline-flex",
    height: "56px",
    width: "360px",
    padding: "35px 14px",
    alignItems: "center",

    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    color: "#64748B",

    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",

    "& input[type='time']::-webkit-calendar-picker-indicator": {

        background: "none !important",
        colo: "white !important"

    },
    "& .Mui-focused fieldset": {
        borderColor: "#FFCA29 !important",
    },
    "input": {
        cursor: "pointer !important" /* Change cursor to pointer to indicate clickable */
    },
    "& .MuiInputBase-root input": {
        backgroundColor: "red !important",
        cursor: "pointer !important"
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "lightblue !important"
    },
    "& input['date']::placeholder": {
        color: "red"
    },
    "& input::placeholder": {
        color: "#64748B",
        opacity: 1
    },

});
const InputLabelBox = styled(Box)({
    margin: "37px 0px 0px 0px",
    display: "flex",
    flexDirection: "column",
    rowGap: "4px"

});
const ModalHeading = styled(Box)({
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
    marginTop: "34.5px"
});
const ModalTimeInputBox = styled(Box)({
    display: "flex",
    columnGap: "10px",
    margin: "10px 0px"
});
const ModlaContainer = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "12px"
});
const BsThreeDotsVertical = styled("img")({
    cursor: "pointer"
});
const FilledSmilyImg = styled("img")({
    width: "29px",
    height: "29px",
});
const BsCalendar = styled("img")({
    cursor: "pointer",
    width: "18.182px",
    height: "20px"
});
const PlusIcon = styled("img")({
    width: "24px"
});
const CiFaceSmile = styled("img")({
    cursor: "pointer",
    width: "39.899px",
    height: "39.899px"
});




export const AntSwitch = withStyles((theme) => ({
    root: {
        width: 47,
        height: 25,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(20px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: "#FFC629",
                // borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 22,
        height: 22,
        boxShadow: 'none',
        color: "#fff"
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 50 / 2,
        opacity: 1,
        backgroundColor: "#E2E8F0",
    },
    checked: {},
}))(Switch);
const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: "#FFC629",
            borderRadius: "8px",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: "#0F172A",

            },
        },
    },
}))(MenuItem);


const styles = (theme: Theme) =>
    createStyles({
        popOverPaper: {

            padding: "4px 4px 2px 4px", borderRadius: "8px",
            border: "1px solid #E2E8F0",
            minWidth: "184px",
            background: "#FFF",

            boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
        },
        EndWaitlistmodalBox: {
            position: "relative",
            backgroundColor: "#fff",
            padding: "85px 106px 31px 98px",
            width: "100%",
            maxWidth: "636px",
            borderRadius: "0px 0px 40px 0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
        },
        
    })

export default withStyles(styles)(UpdateBusinessHours);
export {UpdateBusinessHours}
// Customizable Area End
