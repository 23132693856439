import React from "react";
import { useDropzone } from "react-dropzone";
import { createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Button, withStyles } from "@material-ui/core";
import { cloud } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";

interface Props {
  files: any[];
  postType?: string;
  setFiles: (files: any[]) => void;
  classes: any;
}

const interFF = {
  fontFamily: "Inter"
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      marginTop: "20px"
    },

    dropzone: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignSelf: "stretch",
      alignItems: "center",
      padding: "18px 25px 25px",
      gap: "20px",
      borderRadius: "10px",
      border: "2px dashed #ABB0BC",
    },

    dropzoneInnerContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "4px",
    },

    fileDescriptionContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "10px",
      paddingBottom: "22px",
      "& > p": {
        ...interFF
      }
    },

    dropzoneTitle: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      ...interFF
    },

    dropzoneText: {
      fontSize: "14px",
      lineHeight: "20px",
      ...interFF
    },

    limitsText: {
      fontSize: "14px",
      lineHeight: "25px",
      fontWeight: 700,
    },

    button: {
      width: "360px",
      height: "56px",
    },
    responsiveCheck:{
      "media (max-width: 768px)":{
        dropzone:{
          padding:"15px",
          maxWidth: "100%",
        },
        dropzoneTitle : {
        fontSize: '16px',
        },
        dropzoneText: {
          fontSize: '14px',
        },
        limitsText: {
          fontSize: '12px',

        },
        button: {
          fontSize: '14px',
          padding: '10px 20px',
        },
       
      
      }
    },
    setResponsive:{
      "media (max-width: 768px)":{
       width: '50px',
       height: '50px'
       },
    }
  });

const FileDropzone: React.FC<Props> = ({ files, setFiles, classes, postType }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"],
      "video/mp4": [".mp4", ".MP4"],
    },
    maxSize: postType === "post" ? 20971520 : 2147483648,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });  

  return (
    <section  className={classes.container.responsiveCheck}>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} id="fileInput"/>
        <img
        className="setResponsive"
          src={cloud}
          style={{ width: 72, height: 72, color: "#E0E0E0" }}
        />
        <Box className={classes.dropzoneInnerContainer}>
          <Typography className={classes.dropzoneTitle}>
            {postType === "post" ? "Select video or photo to upload" : "Select video to upload"}
          </Typography>
          <Typography className={classes.dropzoneText} style={{paddingTop: postType === "post" ? "30px" : "0"}}>
            or drag and drop a image file
          </Typography>
        </Box>

        <Box className={classes.fileDescriptionContainer}>
          <Box hidden={postType !== "post"}>
            <Typography className={classes.limitsText}>JPG, PNG, MP4 or MOV</Typography>
          </Box>
          <Typography className={classes.limitsText}>
            720X1280 resolution or higher
          </Typography>
          {postType !== "post" && <Typography className={classes.limitsText}>Up to 1 minute</Typography>}
          <Typography className={classes.limitsText}>{postType === "post" ? "Less than 20 MB" : "Less than 2 GB"}</Typography>
        </Box>

        <CustomButton className={classes.button}>
          Select File
        </CustomButton>
      </div>
    </section>
  );
};

export default withStyles(styles)(FileDropzone);
