import React from "react";

// Customizable Area Start
import {
  Container,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End
// Customizable Area Start
import BusinessEmail from "./BusinessEmail.web";
import BusinessSideBar from "./BusinessSideBar.web";
import PasswordCreate from "./PasswordCreate.web";
import InformationSignup from "./InformationSignup.web";
import BusinessPhoneVerification from "./BusinessPhoneVerification.web";
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";
import EmailAccountLoginErrorBlock from "./EmailAccountLoginErrorBlock.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  container: {
    height: "100%",
    paddingLeft:"0"
  },
  mainContainerForm: {
    width: "100%",
    height: "100%"
  },
  EmailSubtitle: {
    marginTop: "10px",
    color:"#334155",
    fontWeight:"bold"
  },
  nextHomePage: {
    backgroundColor: "#FFC629",
    marginTop: '25px',
    width: "100%"
  },
  BackHomePage: {
    backgroundColor: "#0F172A",
    color: "#fff",
    marginTop: '25px',
    width: "100%"
  },
  imageFirstTab: {
    backgroundColor: "#FFC629",
    borderRadius: "0px 0px 0px 64px",
    width: "100%",
    height: "100%",
    textAlign:"center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  CommunityText: {
    marginRight: "5px"
  },
  BelowCommunityFlex: {
    display: "flex",
    marginTop: '24px',
    alignItems: "center"
  },
  formContainer:{
    display: "flex",
    maxWidth: "330px",
  },
  fromGrid: {
    display: "flex !important",
    justifyContent: 'flex-start',
    alignItems:"center" as "center"
  },
  imagestepsDetail:{
    width: "70%",
    display: "inline-block",
    fontWeight:"bold"
  },
  imageLastTab: {
    position: "absolute",
    bottom: "0",
    left: "11%"
  },
  stepDiv: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  fontWeight: {
    fontWeight:"bold"
  },
 
};
// Customizable Area End
export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
 // Customizable Area Start
  render() {
    const {emailError,formGlobalError,emailAddress,businessPassword,businessConfirmPassword, showPassword, showConfirmPassword, hasCapitalLetter, hasLowerCaseLetter, hasNumber, hasMinLength, currentStep, formError, sideBarContent,firstName,lastName,businessWebsite,zipcode,mobileNumber,otp, } = this.state;
    return (
      // Required for all blocks
     
      <ThemeProvider theme={theme}>
        <Container style={webStyle.container} maxWidth="xl" >
          <Grid container style={webStyle.mainContainerForm}>
            <Grid item md={6} style={webStyle.fromGrid}>
            {formGlobalError[currentStep] && <EmailAccountLoginErrorBlock errorMsg={formGlobalError[currentStep]} />}   

              {currentStep === 1 && <BusinessEmail
               handleFieldChange={this.handleFieldChange}
                emailError={emailError}
               errorMsg={formError}
               emailAddress={emailAddress}
               handleSubmit={this.handleNextClick}
               data-test-id="businessemail"
              />}
              
              {currentStep === 2 && <PasswordCreate
               goToBack={this.handleGoBackClick}
               emailAddress={emailAddress}
               togglePasswordVisibility={this.togglePasswordVisibility}
               showPassword={showPassword}
               showConfirmPassword={showConfirmPassword}
               toggleConfirmPasswordVisibility={this.toggleConfirmPasswordVisibility}
               handleFieldChange={this.handleFieldChange}
               hasCapitalLetter={hasCapitalLetter}
               hasLowerCaseLetter={hasLowerCaseLetter}
               hasNumber={hasNumber}
               hasMinLength={hasMinLength}
               businessConfirmPassword={businessConfirmPassword}
               businessPassword={businessPassword}
               getCheckCircleIconStyle={this.getCheckCircleIconStyle}
               handleSubmit={this.handleNextClick}
               errorMsg={formError}
               data-test-id="emailpassword"
              
               />}

              {currentStep === 3 && <InformationSignup
               goToBack={this.handleGoBackClick} 
               firstName={firstName}
               lastName ={lastName}
               businessWebsite={businessWebsite}
               zipcode={zipcode}
               mobileNumber={mobileNumber}
               errorMsg={formError}
               handleSubmit={this.handleNextClick}
               handleFieldChange={this.handleFieldChange}
               data-test-id="informationsignup"

               />}
               
               {currentStep === 4 && <BusinessPhoneVerification 
               mobileNumber={mobileNumber}
               inputRefs={this.inputRefs}
               handleInputChange={this.handleInputChange}
               handleSubmit={this.handleNextClick}
               handleKeyDown={this.handleKeyDown} 
               otp={otp}
               resendOTP={this.resendOTP}
               goToBack={this.handleGoBackClick}
               errorMsg={formError}
               data-test-id="businessphoneverification"

               />}

            </Grid>
            <Grid item direction='column'
            alignItems='center'
            justifyContent='flex-end' md={6}>
              <BusinessSideBar data={sideBarContent[currentStep]} />
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
