// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography } from "@material-ui/core";
import { fallbackAvatar, plusInCircle } from "./assets";
import AnalyticsEngagedPostsController from "./AnalyticsEngagedPostsController.web";
import moment from "moment";

export class AnalyticsEngagedPosts extends AnalyticsEngagedPostsController {
  render() {
    const { classes, posts } = this.props;

    return (
      <Box className={classes.mainContainer}>
        <Box className={classes.titleContainer}>
          <img src={plusInCircle} alt="plus icon" />
          <Typography className={classes.title}>
            Top Performing Posts
          </Typography>
        </Box>

        <Box className={classes.postsContainer}>
          {Boolean(posts?.length) &&
            posts.map((post) => {
              const authorProfilePicUrl = post.postAuthor.profilePicture
                ? this.baseURL + post.postAuthor.profilePicture
                : fallbackAvatar;

              return (
                <Box key={post.postId} className={classes.post}>
                  <Box className={classes.mainSection}>
                    <Box className={classes.authorDataContainer}>
                      <Box className={classes.profilePic}>
                        <img
                          src={authorProfilePicUrl}
                          alt="author profile picture"
                        />
                      </Box>
                      <Box className={classes.authorCommonData}>
                        <Box className={classes.topBlock}>
                          <Typography className={classes.authorName}>
                            {`${post.postAuthor.firstName || ""} ${post
                              .postAuthor.lastName || ""}`}
                          </Typography>
                          <Typography className={classes.authorAlias}>
                            {""}
                          </Typography>
                          <Typography className={classes.postDate}>
                            {`${moment().diff(
                              moment(post.createdAt),
                              "days"
                            )} days ago`}
                          </Typography>
                        </Box>
                        <Box className={classes.bottomBlock}>
                          <Typography className={classes.followers}>
                            {`${post.postAuthor.followers.length ||
                              0} followers`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.postDataContainer}>
                      {Boolean(post.files?.length) && typeof post?.files?.[0] === "string" &&
                        post?.files?.[0].endsWith(".mp4") && (
                          <Box
                            data-test-id="videoPreview"
                            className={classes.postMediaPreview}
                          >
                            <video controls={false}>
                              <source src={post.files[0]} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </Box>
                        )}
                      {Boolean(post.files?.length) && typeof post?.files?.[0] === "string" &&
                        !post?.files?.[0].endsWith(".mp4") && (
                          <Box
                            data-test-id="imagePreview"
                            className={classes.postMediaPreview}
                          >
                            <img
                              src={post.files[0]}
                              alt="post mediafile preview"
                            />
                          </Box>
                        )}

                      <Typography className={classes.postTextContent}>
                        {post.postContent}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.LCVSection}>
                    <Box className={classes.statsSection}>
                      <Typography className={classes.postStatsHeader}>
                        Likes
                      </Typography>
                      <Typography
                        className={classes.postStatsValues}
                        style={{ color: "#FD6C3F" }}
                      >
                        {post.likes_count}
                      </Typography>
                    </Box>
                    <Box className={classes.statsSection}>
                      <Typography className={classes.postStatsHeader}>
                        Comments
                      </Typography>
                      <Typography
                        className={classes.postStatsValues}
                        style={{ color: "#60C289" }}
                      >
                        {post.comments_count}
                      </Typography>
                    </Box>
                    <Box className={classes.statsSection}>
                      <Typography className={classes.postStatsHeader}>
                        Views
                      </Typography>
                      <Typography
                        className={classes.postStatsValues}
                        style={{ color: "#189CBD" }}
                      >
                        {post?.views_count || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      padding: "51px 70px",
      backgroundColor: "#FFF",
      borderRadius: "20px",
      "& *": {
        fontFamily: "Inter",
      },
      [theme.breakpoints.down(800)]: {
        padding: "20px"
      }
    },

    titleContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "14px",
      alignItems: "center",
      alignSelf: "flex-start",

      "& > img": {
        width: "24px",
        height: "24px",
      },
    },

    title: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
    },

    postsContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "47px 0 5px 0",
    },

    post: {
      display: "flex",
      flexWrap: "nowrap",
      padding: "32px 24px 16px",
      borderBottom: "1px solid #E2E8F0",
      [theme.breakpoints.down(800)]: {
        flexWrap: "wrap",
        padding: "4px",
      }
    },

    mainSection: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      gap: "15px"
    },

    LCVSection: {
      display: "flex",
      justifyContent: "spaceBetween"
    },

    authorDataContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "16px",
    },

    profilePic: {
      width: "56px",
      height: "56px",
      border: "2px solid #F0E5FF",
      borderRadius: "50%",
      overflow: "hidden",

      "& > img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      [theme.breakpoints.down(800)]: {
        width: "44px",
        height: "44px",
      }
    },

    authorCommonData: {
      display: "flex",
      flexDirection: "column",
    },

    topBlock: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "5px",

      "& > p": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
      },
    },

    authorName: {
      color: "#0F172A",
    },

    postDate: {
      color: "#64748B",
    },

    bottomBlock: {
      display: "flex",
      flexWrap: "nowrap",
    },

    followers: {
      fontSize: "14px",
      color: "#64748B",
    },

    postDataContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "20px",
      alignContent: "center",
      height: "96px",
      paddingLeft: "40px",
      [theme.breakpoints.down(800)]: {
        justifyContent: "center",
      }
    },

    postMediaPreview: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#000",
      width: "96px",
      height: "96px",

      "& > img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },

      "& > video": {
        width: "100%",
        height: "100%"
      },
    },

    postTextContent: {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      color: "#000",
    },

    statsSection: {
      display: "flex",
      flexDirection: "column",
      width: "107px",
      [theme.breakpoints.down(800)]: {
        paddingTop: "12px",
        width: "76px"
      }
    },

    postStatsHeader: {
      textAlign: "center",
      fontSize: "16px",
    },

    postStatsValues: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
      textAlign: "center",
      fontSize: "36px",
      fontWeight: 700,
    },
  });

export default withStyles(styles)(AnalyticsEngagedPosts);
// Customizable Area End
