// Customizable Area Start
import React from "react";
import AutomationController from "./AutomationController.web";
import {
  Card,
  Container,
  Grid,
  Typography,
  Box,
  Modal,
  Button,
  IconButton,
  TextField,
  Switch,
  styled
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { stepImageFirst, CloseIcon, EverybrandIcon } from "./assets";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { Formik, Form } from "formik";
import * as yup from "yup";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Link } from "react-router-dom";


const form_schema = yup.object().shape({
  subject: yup.string().required("Subject is required"),
  content: yup.string().required("Content is required"),
});

export class AutomationTemplates extends AutomationController {
  constructor(props:any) {
    super(props);
    this.updateSeemore = this.updateSeemore.bind(this);
  }
  render() {
    const { classes }: any = this.props;
    const endPoint = window.location.pathname;
    const defaultAutomationShow = this.state.defaultautomations.slice(0,this.state.displayItem);
    return (
      <Container className={classes.automationsContainer}>
        <Snackbar
          open={this.state.showAlert}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            data-test-id="webUpdateAlert"
            elevation={6}
            variant="filled"
            severity={"success"}
          >
            {this.state.alertMsg}
          </MuiAlert>
        </Snackbar>
        <Box className={classes.everyBrandBox}>
          <Typography
            data-test-id="autoHeading"
            className={classes.autoHeading}
          >
            Automations & Templates
          </Typography>
          <img src={EverybrandIcon} alt="" />
        </Box>

        <Typography className={classes.autoPara}>
          Reach more customers with less effort and better results.
        </Typography>
        <Typography className={classes.autoSubPara}
        style={{ marginBottom:"20px" }}>
        Save Time With Templates
        </Typography>
        <Grid container className={classes.mainGridBox}>
          {defaultAutomationShow.map((item: any) => (
            <Grid
              item
              md={6}
              lg={6}
              key={item.id}
              data-test-id="automationsMainGrid"
            >
              <Card
                className={classes.autoCards}
                onClick={(event) => {
                  this.openModal(item);
                  this.setAutomation(true);
                }}
                data-test-id="automationsCard"
              >
                <Box className={classes.autosmileBox}>
                  <img
                    src={stepImageFirst}
                    alt="simeimag"
                    style={{ height: "56px", width: "56px" }}
                  />
                </Box>
                <Typography className={classes.autoPara}>
                  {item.attributes.name}
                </Typography>
              </Card>
            </Grid>
          ))}
          {
            <Grid item md={11} className={classes.seeMoreButtonContainer}>
                <Button className={classes.seeMoreButton} onClick={this.updateSeemore}
                data-test-id="updateseemore">
                  See More
                </Button>
             </Grid>
          }
          
        </Grid>
        <Grid container className={classes.mainGridBox}>
          {this.state.userautomtions.map((item: any) => (
            <Grid
              item
              md={6}
              lg={6}
              key={item.id}
              data-test-id="automationsMainGrid"
            >
              <Card
                className={classes.autoCards}
                
                data-test-id="automationsCard"
              >
                <Box className={classes.autosmileBox} 
                  display="flex"
                  justifyContent="space-between">
                  <img
                    src={stepImageFirst}
                    alt="simeimag"
                    style={{ height: "56px", width: "56px" }}
                    onClick={(event) => {
                      this.openModal(item);
                      this.setAutomation(false);
                    }}
                    data-test-id='userautomation'
                  />
                  <Box display="flex">
                  <AntSwitch defaultChecked= {item.attributes.is_active} inputProps={{ 'aria-label': 'ant design' }}
                    onClick={this.updateAutomationActive.bind(this,item.id,!(item?.attributes?.is_active))}                                      
                     />
                  <Box marginLeft="12px">
                  {item.attributes.is_active ? "On" : "Off"}
                  </Box>
                  </Box>
                 
                </Box>
                <Typography className={classes.autoPara}
                  data-test-id='userautomation'
                  onClick={(event) => {
                    this.openModal(item);
                    this.setAutomation(false);
                  }}>
                  {item.attributes.name}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="templateEditModal"
        >
          <Box className={classes.modalBox}>
            <Formik
              data-test-id="templateEditForm"
              initialValues={{
                subject:
                  this.state.selectedTemplate.attributes.message_template
                    .subject,
                content:
                  this.state.selectedTemplate.attributes.message_template
                    .content,
              }}
              validationSchema={form_schema}
              onSubmit={(values) => {
                this.onUpDateTemplate(values);
              }}
            >
              {({ errors, handleChange, handleBlur, values, handleSubmit }) => (
                <Form>
                  <Typography className={classes.modalHeading}>
                    {this.state.isdefaultAutomation ? "Everybrand Templates" : "Set up your automation"}
                  </Typography>
                  <IconButton
                    onClick={this.handleClose}
                    className={classes.crossIcon}
                    data-test-id="iconCrossButton"
                  >
                    <img src={CloseIcon} alt="" />
                  </IconButton>
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography className={classes.inputName}>
                      {
                        this.state.isdefaultAutomation && "Template Title" || "When this happens:"
                      }
                    </Typography>
                    <TextField
                      className={classes.inputField}
                      data-test-id="subject"
                      variant="outlined"
                      label={this.state.isdefaultAutomation ? "Use a title that describes when to use this template" : "A contact form lead is received"}
                      value={values.subject}
                      onChange={handleChange("subject")}
                      error={Boolean(errors.subject)}
                      helperText={errors.subject}
                      onBlur={handleBlur("subject")}
                    />
                    <Typography className={classes.inputName}>
                      {
                        this.state.isdefaultAutomation && "Template Message" || " Send This Message:"
                      }

                    </Typography>
                    <TextField
                      className={classes.inputField}
                      data-test-id="content"
                      variant="outlined"
                      label="Message"
                      multiline
                      rows={8}
                      value={values.content}
                      onChange={handleChange("content")}
                      error={errors.content ? true : false}
                      helperText={errors.content}
                      onBlur={handleBlur("content")}
                    />
                  </Box>
                  <Box className={classes.btnBox}>
                    {
                      this.state.isdefaultAutomation && <>
                        <Button
                          data-test-id="continueBtn"
                          type="submit"
                          variant="contained"
                          className={classes.SaveChangesBtn}
                        //   onClick={() => handleSubmit()}
                        >
                          Save Changes
                        </Button>
                        <Link to='/messages'>
                        <Button
                          type="submit"
                          variant="contained"
                          className={classes.useTemplateBtn}
                          >
                          Use Template

                        </Button>
                        </Link>
                       
                      </> ||
                       <>
                        <Button
                          data-test-id="continueBtn"
                          type="submit"
                          variant="contained"
                          className={classes.continueBtn}
                        >
                          Save
                        </Button>
                        <IconButton>
                          <MenuOpenIcon />
                        </IconButton>
                      </>
                    }
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </Container>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    everyBrandBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    mainGridBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    btnBox: {
      margin: "10px 0",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    continueBtn: {
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      textTransform: "none",
      color: "#0F172A",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      padding: "10px 50px",
    },
    SaveChangesBtn: {
      backgroundColor: "#0F172A",
      borderRadius: "8px",
      textTransform: "none",
      color: "#FFFFFF",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      padding: "10px 35px",
    },
    useTemplateBtn: {
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      textTransform: "none",
      color: "#0F172A",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      padding: "10px 35px",
    },
    inputField: {
      width: "100%",
      margin: "10px 0",
    },
    inputName: {
      color: "#334155",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
    },
    crossIcon: {
      top: "10px",
      right: "10px",
      position: "absolute",
    },
    modalHeading: {
      color: "#000",
      textAlign: "center",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },
    modalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "32px 48px 24px",
      width: "480px",
      borderRadius: "0px 0px 40px 0",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
      [theme.breakpoints.down(480)]: {
        width: "96%"
      }
    },
    autoCards: {
      padding: "20px 30px",
      width: "90%",
      height: "187px",
      margin: "10px 0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "&:hover": {
        cursor: "pointer",
        boxShadow: "",
      },
    },
    automationsContainer: {
      padding: "50px",
      [theme.breakpoints.down(950)]: {
        padding: "24px",
      }
    },
    autoHeading: {
      color: "#000",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      marginBottom: "20px",
    },
    autoPara: {
      color: "#000",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "-0.12px",
      marginBottom: "25px",
    },
    autoSubPara: {
      color: "#000",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },
    seeMoreButtonContainer : {
      display:"flex",
      justifyContent:"end",
      marginTop:"17px"
    },
    seeMoreButton: {
      width: '233px',
      padding: '8px',
      borderRadius: '8px',
      backgroundColor: '#FFC629',
      textTransform: 'none',
      color: '#0F172A',
      fontFamily: 'Inter', 
      fontSize: '16px', 
      fontWeight: 700, 
      lineHeight: '24px', 
      textAlign: 'center', 
      cursor:"pointer"
    },
});
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 17,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2.9,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#FFC629',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:'#E2E8F0',
      boxSizing: 'border-box',
    },
}));
  
export default withStyles(styles)(AutomationTemplates);
// Customizable Area End
