// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Snackbar
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { EverybrandIcon } from "./assets";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import NotificationPreferencesController from "./NotificationPreferencesController.web";
import MuiAlert from "@material-ui/lab/Alert";

export class NotificationPrefrences extends NotificationPreferencesController {
  getSwitchBtnText(switchValue: boolean) {
    return switchValue ? "On" : "Off"
  }

  getSwitchClass(switchValue: boolean, classes: any) {
    return switchValue ? classes.notificationActiveSwitch
      : classes.notificationiInActiveSwitch
  }
  render() {
    const { classes }: any = this.props;
    return (
      <Container maxWidth={false} className={classes.notificationPreferencesContaioner}>
        <Snackbar
          open={this.state.showSuccessAlert}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={"success"}
          >
            Notification preferences updated successfully
          </MuiAlert>
        </Snackbar>
        <Box className={classes.notificationPreferencesHeader}>
          <Typography
            className={classes.notificationPreferencesHeading}
          >
            Notification Preferences
          </Typography>
          <img src={EverybrandIcon} alt="" />
        </Box>
        <Box className={classes.notificationSubBox}>
          <Typography
            className={classes.notificationPreferencesSubHeading}
          >
            Summary Text
          </Typography>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>24-hour Notifications</Typography>
            <Box className={classes.swtichInner}>

              <Box
                data-test-id="hourNotification"
                onClick={() => { this.setState({ hourNotification: !this.state.hourNotification }) }}
                className={
                  this.getSwitchClass(this.state.hourNotification, classes)

                }
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.hourNotification)}</Typography>
            </Box>
          </Box>
        </Box>
        <hr className={classes.nPdivider} />
        <Box className={classes.notificationSubBox}>
          <Typography
            className={classes.notificationPreferencesSubHeading}
          >
            Social Media
          </Typography>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>New Comments Desktop</Typography>
            <Box className={classes.swtichInner}>

              <Box
                data-test-id="commentsDesktop"
                onClick={() => { this.setState({ newCommentsDesktop: !this.state.newCommentsDesktop }) }}
                className={
                  this.getSwitchClass(this.state.newCommentsDesktop, classes)
                }
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.newCommentsDesktop)}</Typography>
            </Box>
          </Box>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>New Comments Email</Typography>
            <Box className={classes.swtichInner}>

              <Box
                data-test-id="commentsEmail"
                onClick={() => { this.setState({ newCommentsEmail: !this.state.newCommentsEmail }) }}
                className={
                  this.getSwitchClass(this.state.newCommentsEmail, classes)
                }
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.newCommentsEmail)}</Typography>
            </Box>
          </Box>
        </Box>
        <hr className={classes.nPdivider} />
        <Box className={classes.notificationSubBox}>
          <Typography
            className={classes.notificationPreferencesSubHeading}
          >
            New Followers
          </Typography>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>Desktop</Typography>
            <Box className={classes.swtichInner}>

              <Box
                data-test-id="desktop"
                onClick={() => { this.setState({ desktop: !this.state.desktop }) }}
                className={
                  this.getSwitchClass(this.state.desktop, classes)
                }
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.desktop)}</Typography>
            </Box>
          </Box>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>Email</Typography>
            <Box className={classes.swtichInner}>

              <Box
                onClick={() => { this.setState({ email: !this.state.email }) }}
                className={
                  this.getSwitchClass(this.state.email, classes)
                }
                data-test-id="email"
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.email)}</Typography>
            </Box>
          </Box>
        </Box>
        <hr className={classes.nPdivider} />

        <Box className={classes.notificationSubBox}>
          <Typography
            className={classes.notificationPreferencesSubHeading}
          >
            Reviews
          </Typography>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>New Reviews Desktop</Typography>
            <Box className={classes.swtichInner}>

              <Box
                onClick={() => { this.setState({ newReviewsDesktop: !this.state.newReviewsDesktop }) }}
                className={
                  this.getSwitchClass(this.state.newReviewsDesktop, classes)
                }
                data-test-id="reviewDesktop"
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.newReviewsDesktop)}</Typography>
            </Box>
          </Box>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>New Reviews Email</Typography>
            <Box className={classes.swtichInner}>

              <Box
                onClick={() => { this.setState({ newReviewsEmail: !this.state.newReviewsEmail }) }}
                className={
                  this.getSwitchClass(this.state.newReviewsEmail, classes)
                }
                data-test-id="reviewEmail"
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.newReviewsEmail)}</Typography>
            </Box>
          </Box>
        </Box>
        <hr className={classes.nPdivider} />

        <Box className={classes.notificationSubBox}>
          <Typography
            className={classes.notificationPreferencesSubHeading}
          >
            Messages
          </Typography>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>All New Messages Desktop</Typography>
            <Box className={classes.swtichInner}>

              <Box
                onClick={() => { this.setState({ allNewMessageDesktop: !this.state.allNewMessageDesktop }) }}
                className={
                  this.getSwitchClass(this.state.allNewMessageDesktop, classes)
                }
                data-test-id="messageDesktop"
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.allNewMessageDesktop)}</Typography>
            </Box>
          </Box>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>All New Messages Email</Typography>
            <Box className={classes.swtichInner}>

              <Box
                onClick={() => { this.setState({ allNewMessageEmail: !this.state.allNewMessageEmail }) }}
                className={
                  this.getSwitchClass(this.state.allNewMessageEmail, classes)
                }
                data-test-id="messageEmail"
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.allNewMessageEmail)}</Typography>
            </Box>
          </Box>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>Only new messages assigned to you Desktop</Typography>
            <Box className={classes.swtichInner}>

              <Box
                onClick={() => { this.setState({ onlyNewMessagesAssignedToDesktop: !this.state.onlyNewMessagesAssignedToDesktop }) }}
                className={
                  this.getSwitchClass(this.state.onlyNewMessagesAssignedToDesktop, classes)
                }
                data-test-id="assignedDesktop"
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.onlyNewMessagesAssignedToDesktop)}</Typography>
            </Box>
          </Box>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>Only new messages assigned to you Email</Typography>
            <Box className={classes.swtichInner}>

              <Box
                onClick={() => { this.setState({ onlyNewMessagesAssignedToEmail: !this.state.onlyNewMessagesAssignedToEmail }) }}
                className={
                  this.getSwitchClass(this.state.onlyNewMessagesAssignedToEmail, classes)
                }
                data-test-id="assignedEmail"
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.onlyNewMessagesAssignedToEmail)}</Typography>
            </Box>
          </Box>
        </Box>
        <hr className={classes.nPdivider} />

        <Box className={classes.notificationSubBox}>
          <Typography
            className={classes.notificationPreferencesSubHeading}
          >
            Conversations
          </Typography>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>Existing message conversations assigned to you  Desktop</Typography>
            <Box className={classes.swtichInner}>

              <Box
                onClick={() => { this.setState({ existingMessagesAssignedToDesktop: !this.state.existingMessagesAssignedToDesktop }) }}
                className={
                  this.getSwitchClass(this.state.existingMessagesAssignedToDesktop, classes)
                }
                data-test-id="existingDesktop"
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.existingMessagesAssignedToDesktop)}</Typography>
            </Box>
          </Box>
          <Box className={classes.notificationBox}>
            <Typography className={classes.notificationText}>Existing message conversations assigned to you  Email</Typography>
            <Box className={classes.swtichInner}>

              <Box
                onClick={() => { this.setState({ existingMessagesAssignedToEmail: !this.state.existingMessagesAssignedToEmail }) }}
                className={
                  this.getSwitchClass(this.state.existingMessagesAssignedToEmail, classes)
                }
                data-test-id="existingEmail"
              >
                <FiberManualRecordIcon style={{ color: "#FFF" }} />
              </Box>
              <Typography className={classes.notificationText}>{this.getSwitchBtnText(this.state.existingMessagesAssignedToEmail)}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.saveBtnBox}>
          <Button
            data-test-id="saveBtn"
            onClick={() => { this.updateNotificaitonPreferences() }}
            variant="contained"
            className={classes.saveBtn}
          >
            Save
          </Button>
        </Box>
      </Container>
    );
  }
}

export const commonCss: any = {
  FLSB: {
    display: "flex",
    justifyContent: "space-between"
  }

};
const styles = (theme: Theme) =>
  createStyles({
    notificationPreferencesContaioner: {
      padding: "50px",
      [theme.breakpoints.down(950)]: {
        padding: "12px"
      },
    },
    notificationPreferencesHeader: {
      ...commonCss.FLSB,
      paddingBottom: "50px"
    },
    notificationSubBox: {
      // padding: "50px",
    },
    notificationBox: {
      ...commonCss.FLSB,
      paddingBottom: "15px",
      paddingTop: "15px"
    },
    notificationPreferencesHeading: {
      color: "#000",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },
    notificationActiveSwitch: {
      backgroundColor: "#FFC629",
      width: "47px",
      height: "25px",
      borderRadius: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      cursor: "pointer",
      marginRight: "20px"
    },
    notificationiInActiveSwitch: {
      backgroundColor: "#ABB0BC",
      width: "47px",
      height: "25px",
      borderRadius: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      cursor: "pointer",
      marginRight: "20px"
    },
    notificationPreferencesSubHeading: {
      font: "Inter",
      fontWeight: 700,
      fontSize: "14px",
      paddingTop:"15px",
      paddingBottom:"15px"
    },
    notificationText: {
      font: "Inter",
      fontWeight: 400,
      fontSize: "14px"
    },
    nPdivider: {
      height: '1px',
      border: 'none',
      backgroundColor: '#DADADA',
      // margin: "33px 0px 32px 0px",
    },
    swtichInner: {
      display: "flex"
    },
    saveBtn: {
      backgroundColor: "#FFC629",
      width: "358px",
      height: "56px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      padding: "5px 40px",
    },
    saveBtnBox: {
      textAlign: "right",
      paddingBottom: "30px",
      paddingTop: "30px"
    }
  });

export default withStyles(styles)(NotificationPrefrences);
// Customizable Area End
