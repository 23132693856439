// Customizable Area Start
import React from "react";
import {
    Button,
    Container,
    Typography,

    Box,
    IconButton,
    Popover,

    TextField,

    Avatar,

    FormControl,
    NativeSelect,
    Paper,
    MenuItem,
    ListItemIcon,
    ListItemText,
    InputAdornment,
} from "@material-ui/core";

import {
    withStyles,
    createStyles,
    Theme,
    styled,
} from "@material-ui/core/styles";

import 'react-datepicker/dist/react-datepicker.css';
import WaitListController, { Props } from "./WaitListController.web";
import { ThreeDotIcon, stepImageFirst, filledOpenSpot, sendIcon, removeIcon, folderIcon } from "./assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/Search';
import moment from "moment";



export class WaitListChat extends WaitListController {
   
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes }: any = this.props;
        const open = Boolean(this.state.anchorEl)
        const id = open ? 'simple-popover' : undefined;

const { location } = this.props;
const name = location.state ? location.state.name : '';


        return (
            <>
                <Container maxWidth={false} className={classes.websiteMainCOntainer}>
                    <Box className={classes.waitListOrder} >
                        <TextField
                            className={classes.searchField}
                            data-test-id="content"
                            variant="outlined"
                            placeholder="Search"
                            InputProps={{
                                classes: {
                                    root: classes.customSearchField,
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: "#94A3B8" }} />
                                    </InputAdornment>
                                ),

                            }}

                        // value={values.content}
                        // onChange={handleChange("content")}
                        // error={errors.content ? true : false}
                        // helperText={errors.content}
                        // onBlur={handleBlur("content")}
                        />
                        <Typography className={classes.waitListOrderHeading}>Waitlist Contact Order</Typography>

                        <div
                            style={{

                                width: "100%",
                                marginTop: "17px",

                            }}
                        >


                            <HrLine />



                        </div>
                        {this.state.waitListContactsData.map((item: any, index: number) => {

                            return (
                                <div
                                onClick={() => this.chatMemberChange(item.attributes.first_name + " " + item.attributes.last_name)} 

                                    className={
                                        this.state.selectedContactName === item.attributes.first_name + " " + item.attributes.last_name
                                            ? classes.selectedMainDiv
                                            : classes.mainDiv
                                    }
                                    data-test-id="first">
                                    <div id="fisrtContact" style={{
                                        background: "#fff",
                                        borderRadius: "57.857px",
                                        border: "1px solid #DADADA", width: "53px", height: "54px"
                                    }}>
                                        <img
                                            src={stepImageFirst}
                                            alt="simeimag"
                                            style={{ height: "38.47px", width: "37.76px", margin: "6.04px" }}
                                        />
                                    </div>
                                    <div className={classes.content}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography className={classes.name}>{item.attributes.first_name + " " + item.attributes.last_name}</Typography>
                                            <span className={classes.bullet}></span>
                                        </div>
                                        <Typography className={classes.contactInfoNumber} >Date of Future Appointment</Typography>
                                        <Typography className={classes.contactInfoMail}>{moment(item.attributes.appointment_date).format('MM/DD/YYYY')}</Typography>

                                    </div>
                                    <div className={classes.actions}>
                                        <div>
                                            <StyledThreeDotIcon data-test-id="threeDotIcon"
                                                src={ThreeDotIcon} alt=""
                                                onClick={this.handleClick}
                                            />
                                        </div>

                                    </div>

                                </div>
                            )
                        })}


                        <Popover
                            id={id}

                            open={open}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            classes={{
                                paper: classes.popOverPaper
                            }}
                        >


                            <StyledMenuItem className="checkResponsive">
                                <ListItemIcon classes={{
                                    root: classes.listItemIcon
                                }}>
                                    <img src={filledOpenSpot} alt="ggg" />
                                </ListItemIcon>
                                <ListItemText primary="Filled open spot " style={{
                                    display: "flex",

                                    paddingLeft: "8px"
                                }} />
                                <div>
                                    <ListItemIcon style={{
                                        display: "flex",

                                        paddingLeft: "43px"
                                    }}>
                                        <MoreVertIcon fontSize="small" />
                                    </ListItemIcon>
                                </div>
                            </StyledMenuItem>
                            <StyledMenuItem>
                                <ListItemIcon classes={{
                                    root: classes.listItemIcon
                                }}>
                                    <UnfoldMoreIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText style={{
                                    display: "flex",

                                    paddingLeft: "8px"
                                }} primary="Passed on spot" />
                            </StyledMenuItem>
                            <StyledMenuItem>
                                <ListItemIcon classes={{
                                    root: classes.listItemIcon
                                }}>
                                    <img src={removeIcon} alt="ggg" />
                                </ListItemIcon>
                                <ListItemText style={{
                                    display: "flex",

                                    paddingLeft: "8px"
                                }} primary="Remove waitlist contact" />
                            </StyledMenuItem>



                        </Popover>
                    </Box>
                    <Box className={classes.chat} >
                        <div className={classes.chatHeader}>
                            <div style={{
                                justifyContent: "center", display: "flex", alignItems: "center",
                                gap: "4px",
                                position: "absolute", top: 0, left: 20
                            }}>
                                <StyledAvatar alt="Michelle Jones" src="/path-to-avatar.jpg" />

                                <Typography style={{

                                    marginLeft: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    color: " #0F172A",

                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "22px"

                                }}>{this.state.selectedContactName}</Typography>
                                <span className={classes.bulletinChat}></span>
                            </div>
                            <div>
                                <Typography className={classes.middleHeaderPart}
                                >Filled Open Spot</Typography>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                position: "absolute", top: 0, right: 20
                            }}>
                                <img src={folderIcon} alt="ggg" />
                                <Typography className={classes.lastItemOfHeader}>{name}</Typography>
                            </div>
                        </div>
                        <div className={classes.separator}></div>

                        {/* Profile Image */}
                        <div className={classes.profileImageContainer}>
                            <div className={classes.separator}></div>
                            <StyledAvatarBetweenSeparator alt="Michelle Jones" src="/path-to-avatar.jpg" />


                            <div className={classes.separator}></div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Typography variant="caption" style={{
                                color: " #0F172A",

                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "22px"
                            }}>{this.state.selectedContactName}</Typography>

                        </div>
                        <div style={{ textAlign: "center" }}>

                            <Typography variant="caption" style={{
                                color: "#64748B",

                                fontFamily: "Inter",
                                fontSize: "10px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "22px"
                            }}>Today at 10:50 AM</Typography>
                        </div>
                        {/* Messages Container */}
                        <div className={classes.messagesContainer}>
                            <ReceiverMessageContainer>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <StyledAvatar style={{width:'32px',height:'32px',borderRadius:'20px'}}  alt="Michelle Jones" src="/path-to-avatar.jpg" />
                                </Box>
                                <Box >
                                    <MessageBubble>
                                        <Typography style={{marginLeft: '30px'}} variant="body1">
                                            Hey 
                                        </Typography>
                                    </MessageBubble>

                                </Box>
                                <IconButton>
                                    <FavoriteBorderIcon />
                                </IconButton>
                            </ReceiverMessageContainer>
                            <div
                                style={{
                                    display: "flex",

                                }}
                            >
                                <Timestamp> Michelle Jones 10:50 AM</Timestamp>


                                <IconButton style={{ padding: "0" }}>
                                    <DoneAllIcon style={{ marginLeft: "10.79px", fill: "#0F172A" }} />
                                </IconButton>
                            </div>

                            <MessageContainer>

                                <Box >
                                    <SenderMessageBubble>
                                        <Typography variant="body1">
                                            Hey there! I was wondering if you have any recommendations for a good coffee shop in the area. I'm in need of a caffeine fix!

                                        </Typography>
                                    </SenderMessageBubble>

                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                        marginLeft: "17px"
                                    }}
                                >
                                    <StyledAvatar style={{width:'32px',height:'32px',borderRadius:'20px'}} alt="Michelle Jones" src="/path-to-avatar.jpg" />
                                </Box>

                            </MessageContainer>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginRight: "21px"
                                }}
                            >
                                <IconButton style={{ padding: "0" }}>
                                    <DoneIcon style={{ marginLeft: "10.79px", fill: "#0F172A" }} />
                                </IconButton>
                                <Timestamp> Michelle Jones 10:50 AM</Timestamp>



                            </div>

                        </div>

                        {/* Send Message Part */}
                        <div style={{ marginTop: "101px" }}>
                            <div className={classes.sendMessage}>
                                <div className={classes.profileImageShowInMessage}>
                                    <img
                                        src={stepImageFirst}
                                        alt="Profile"
                                        className={classes.profileImage}
                                    />
                                </div>

                                <TextField
                                    className={classes.messageInput}
                                    variant="outlined"
                                    placeholder="Type a message..."
                                    multiline
                                    fullWidth
                                    maxRows={11}
                                />

                                <div className={classes.iconContainer}>
                                    <img src={sendIcon} alt="fgg" />
                                </div>
                                <div className={classes.iconContainer}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.sendButton}
                                    >
                                        Send
                                    </Button>
                                </div>


                            </div>
                        </div>
                    </Box>
                </Container>
            </>
        );
    }
}

const HrLine = styled("hr")({
    width: "100%",
    strokeWidth: "1px",
    stroke: "rgba(100, 116, 139, 0.20)",
    margin: 0

});

const StyledThreeDotIcon = styled("img")({
    cursor: "pointer"
})


const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: "#FFC629",
            borderRadius: "8px",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: "#0F172A",

            },
        },
    },
    select: {
        '&.MuiNativeSelect-select:focus': {
            backgroundColor: 'white',
        },
    },
    overrides: {
        '&.MuiNativeSelect-select:focus': {
            backgroundColor: 'white',
        },
    }
}))(MenuItem);


const MessageContainer = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
    width: "94%",
    marginLeft: "auto"
});
const ReceiverMessageContainer = styled("div")({
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px",
    width: "94%",
    marginRight: "auto"
});

const StyledAvatar = styled(Avatar)({
    marginRight: "10px",

});

const StyledAvatarBetweenSeparator = styled(Avatar)({

    width: "44px",
    height: "44px",
    borderRadius: "50%",
    border: "1px solid #CCC",
    marginRight: "16px",
    marginLeft: "16px"
});



const MessageBubble = styled(Paper)({
    padding: "9px 24px 9px 33px",

    borderRadius: "20px 20px 20px 0px",
    background: "#F1F5F9",
});

const SenderMessageBubble = styled(Paper)({
    padding: "9px 24px 9px 33px",

    borderRadius: "20px 20px 0px 20px",
    background: "#FFC629",
});

const Timestamp = styled(Typography)({
    color: "#64748B",

    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    marginLeft: "10px",
});


const styles = (theme: Theme) =>
    createStyles({
        sendButton: {
            textTransform: "none",
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px"/* 150% */
        },

        listItemIcon: {
            minWidth: "0px"
        },
        popOverPaper: {

            padding: "4px 4px 2px 4px", borderRadius: "8px",
            border: "1px solid #E2E8F0",

            background: "#FFF",

            boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
        },
        bullet: {
            width: "12px",
            background: "#30D158",
            borderRadius: "100%",
            height: "12px",
            marginTop:"5px"
        },
        bulletinChat: {
            width: "12px",
            background: "#30D158",
            borderRadius: "100%",
            height: "12px",
          
        },
        middleHeaderPart: {
            width: "186px",
            height: "32px",
            padding: "6px 10px",
            marginRight: '20px',
            justifyContent: "center",
            alignItems: "center",
            display: "flex",

            borderRadius: "8px",
            background: "#FFC629",
            color: "#000",
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px"



        },
        lastItemOfHeader: {
            color: "#000",

            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px"
        },
        statusIndicator: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "4px",


            color: "#30D158"
        },
        websiteMainCOntainer: {
            display: "flex",
            flexWrap: "wrap"
        },
        searchField: {


            width: "100%"

        },
        customSearchField: {
            background: "#F7F8FC",
            borderRadius: '98px',

        },
        waitListOrderHeading: {
            color: "#000",
            fontFamily: "Inter",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px",
            letterSpacing: "-0.12px",

            marginTop: "14px",
            marginLeft: "19px"
        },
        filterby: {
            display: "flex",
            alignItems: "center",
            marginTop: "17px",
            [theme.breakpoints.down(991)]: {
                width: "100%",
                flex: "0 0 100%",
            },
        },

        select: {
            // Add styles specifically for the .MuiNativeSelect-select class
            '&.MuiNativeSelect-select:focus': {
                // Override or add your styles here
                // For example:
                backgroundColor: 'white', // Set the background color to its initial value
            },
        },
        selectField: {
            width: "100%",

            borderRadius: "8px",
            border: "1px solid #CBD5E1",
            padding: "1px 8px",

            background: "#FFF",




        },
        filterFonts: {
            color: "#64748B",

            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "22px"
        },
        messageDay: {
            color: "#64748B",

            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22px"
        },
        mainDiv: {
            display: "flex",
            gap: '10px',
            padding: "8px 7px 10.97px 13px",
            marginBottom: "14px",
            position: "relative",
            marginTop: "21.97px",

        },
        selectedMainDiv: {
            display: "flex",
            gap: '10px',
            padding: "8px 7px 10.97px 13px",
            marginBottom: "14px",
            marginTop: "21.97px",
            position: "relative",
            borderRadius: "6px",
            backgroundColor: "#D9D9D9",
            "&:hover": {
                backgroundColor: "#D9D9D9",
            },
        },

        content: {
            width: "70%",

        },
        actions: {
            position: "absolute",
            right: "13px",
            top: "50%",
            transform: "translateY(-50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        name: {
            display: "flex",
            position: "relative",
            gap: "44px",
            color: "#0F172A",

            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px"
        },
        contactInfoNumber: {
            marginTop: "4px",
            color: "#64748B",
            fontFamily: "Inter",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "8px",

        },
        contactInfoMail: {
            color: "#64748B",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "8px",
            marginTop: "10px",


        },
        waitListOrder: {
            flex: "0 0 30%",
            overflow: "auto",
            maxHeight: "897px",
            background: "rgb(255, 255, 255)",

            boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",

            marginTop: "20.97px",
            marginLeft: "18px",
            marginRight: "34px",
            marginBottom: "30.03px",
            padding: "35px 25px 0px 28px",
            [theme.breakpoints.down(991)]: {
                width: "100%",
                flex: "0 0 100%",
            },
        },
        chat: {
            background: "rgb(255, 255, 255)",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
            flex: 1,
            marginTop: "24.97px",
            marginRight: "30px",
            marginBottom: "26.03px",
            padding: "21.03px 16px 28.71px 16px"

        },
        chatHeader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center", //
            padding: "4px 5px 15px",
            borderBottom: "1px solid #CCC",
            position: "relative"
        },
        messagesContainer: {
            height: "500px",
            overflowY: "auto",
            padding: "10px",
            "&::-webkit-scrollbar": {
                width: "5px",
                height: "160px"

            },
            "&::-webkit-scrollbar-thumb":
            {
                borderRadius: "10px",

                backgroundColor: "#CBD5E1",
            },
            "&::-webkit-scrollbar-track":
            {

                borderRadius: "10px",
                backgroundColor: "#CBD5E1"
            }
        },
        sendMessage: {

            padding: "10px",
            borderTop: "1px solid #CCC",
            display: "flex",

            gap: "10px",
        },
        separator: {
            width: "100%",
            height: "1px",
            background: "rgba(100, 116, 139, 0.20)",
        },
        profileImageContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "18px",


        },
        profileImageShowInMessage: {
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",


        },
        profileImage: {
            width: "44px",
            height: "44px",
            borderRadius: "50%",
            border: "1px solid #CCC",
            marginRight: "16px",
            marginLeft: "16px"
        },
        textContainer: {
            marginTop: "10px",
            textAlign: "center",
        },
        iconContainer: {
            display: "flex",
            alignItems: "flex-end",
            paddingLeft: "27px"
        },
        checkResponsive:{
            "media (max-width: 768px)":{
                width: '50px',
                height: '50px'
                },
        }

    })
export default withStyles(styles)(WaitListChat);
// Customizable Area End
