import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createStyles, createTheme, makeStyles, Theme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import SettingsMenu from "./SettingsMenu.web";
import GettingStarted from './GettingStarted.web';
import UpdateBusinessContact from './UpdateBusinessContact.web';
import { Settings } from './enums';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CustomizePostingFeed from './CustomizePostingFeed.web';
import CustomizeEveryReelButton from './CustomizeEveryReelButton.web'
import BillingInformation from "./BillingInformation.web";
import SocialIntegrations from "./SocialIntegrations.web";
import UpdateBusinessHours from "./UpdateBusinessHours.web";
import Websites from "./Websites.web";
import TeamMatesWeb from "./Teammates.web";
import UpdatesubscriptionWeb from "./Updatesubscription.web";
import AutomationTemplatesWeb from "./AutomationTemplates.web";
import SocialPhoneNumberWeb from "./SocialPhoneNumber.web";
import NotificationPrefernceWeb from "./NotificationPreferences.web";
import  ConnectWebsiteWeb  from "./ConnectWebsite.web";


const theme = createTheme({
  palette: {
    background: {
      default: '#F1F5F9'
    }
  }
});

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller.web";
// Customizable Area End

class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { selectedMenu, currentBusinessContactInfo, countryCodesWithFlags,
      isSnackbarOpen,  alert, isFormSubmitting, currentPostingFeedInfo, currentEveryReelButtonInfo } = this.state;
    // Customizable Area End
     
   
    
 
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Snackbar 
          open={isSnackbarOpen} 
          autoHideDuration={6000} 
          onClose={() => {this.setSnackbarState(false)}}
          anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
        >
          <MuiAlert 
            elevation={6} 
            variant="filled"
            severity={alert.severity} 
            onClose={() => this.setSnackbarState(false)}
          >
            {alert.message}
          </MuiAlert>
        </Snackbar>
        <Box display={'flex'}>
          <Box style={{display: this.state.isSettingDrawerOpen, margin: "auto"}}>
            <SettingsMenu
              selectedMenu={selectedMenu}
              onMenuSelect={this.handleSettingsMenuSelection}
              isUpdateSubscriptionDisabled={this.state.isUpdateSubscriptionDisabled}
            />
          </Box>
          <Box className={classes.sidebar} style={{display: this.state.isSettingsVisible}}>
            {selectedMenu === Settings.GettingStarted && <GettingStarted />}
            {selectedMenu === Settings.BillingInfo && <BillingInformation navigation={this.props} id={""} />}
            {selectedMenu === Settings.UpdateBusinessContactInfo && (
              <UpdateBusinessContact
                busninessContactInfo={currentBusinessContactInfo}
                countryCodesWithFlags={countryCodesWithFlags}
                onUpdateBusinessContactInfo={this.updateCurrentBusinessContactInfo}
                isFormSubmitting={isFormSubmitting}
                validationSchema={this.validationSchema}
              />
            )}
            {selectedMenu === Settings.CustomizePostingFeed && (
              <CustomizePostingFeed id={""} navigation={undefined}               
              />  
            )}
            {selectedMenu === Settings.CustomizeEveryreelBtn && (
              <CustomizeEveryReelButton
                everyReelButtonInfo={currentEveryReelButtonInfo}
                onUpdateEveryReelButtonInfo={this.updateCurrentEveryReelButtonInfo}
                isFormSubmitting={isFormSubmitting}
                validationSchemaCustomiseEveryReel={this.validationSchemaCustomiseEveryReel}
              />
            )}
            {selectedMenu === Settings.SocialIntegrations && (<SocialIntegrations navigation={this.props} id={""}/>
            )}
              {selectedMenu === Settings.ConnectWebsite && (<ConnectWebsiteWeb navigation={this.props} id={""}/>
            )}
            {selectedMenu === Settings.UpdateBusinessHours && (<UpdateBusinessHours navigation={this.props} id={""}/>
            )}
            {selectedMenu === Settings.Websites && (<Websites navigation={this.props} id={""}/>)}
            {selectedMenu === Settings.TeamMates &&(<TeamMatesWeb navigation={this.props.navigation} id={""}/>)}
            {selectedMenu === Settings.UpdateSubscription   && (<UpdatesubscriptionWeb navigation={this.props} id={""}/>)}
            {selectedMenu === Settings.NotificationPreferences && (<NotificationPrefernceWeb navigation={this.props} id={""}/>)}
            {selectedMenu === Settings.AutomationTemplates && (<AutomationTemplatesWeb navigation={this.props} id={""}/>)}
            {selectedMenu === Settings.SocialPhoneNumber && (<SocialPhoneNumberWeb navigation={this.props} id={""}/>)}
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: 'calc(100vh - 64px)'
    },
    sidebar: {
      height:"calc(100vh - 64px)",
      overflowX:"hidden",
      width:"100%",
      [theme.breakpoints.down(950)]: {
        margin: "auto",
      }
    }
  })

export { Settings2 };
export default withStyles(styles)(Settings2);
// Customizable Area End
