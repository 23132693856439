import React from 'react'
import { AppBar, Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { backArrow } from './assets';

interface Props {
    backButton: () => void;
    icon?: any;
    name: string;
    children?: React.ReactNode;
}

function NavigationMenuMobile({backButton, icon, name}: Props){
  const classes = useStyles();
  
    return (
        <AppBar className={classes.appbar}>
          <Box className={classes.appbarBox} onClick={() => {backButton()}} data-test-id="backbutton">
            <img src={backArrow} alt="" className={classes.icon}/>
            <img src={icon} alt="" className={classes.icon}/>
            <Typography className={classes.name}>{name}</Typography>
          </Box>
        </AppBar>
    );
}

export default NavigationMenuMobile;


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appbar: {
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.drawer + 1,
        overflowX: "auto",
      },
      
      appbarBox: {
        display: "flex",
        gap: '12px',
        background: "#FFC629",
        padding: "12px"
      },
      icon: {
        width: "24px",
        height: "24px"
      },
      name: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#0F172A"
      }
    })
);